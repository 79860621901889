import {
  Button,
  Icon,
  IconButton,
  Scaffold,
  ScaffoldFooter,
  useAnnaAppContext,
} from "@anna-money/anna-web-ui";
import { type FC, type ReactNode, useCallback, useState } from "react";
import { useServices } from "../../common/services/servicesContext";
import styles from "./chatSurveyScaffold.module.scss";

export const ChatSurveyScaffold: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { authStore } = useServices();
  const { displayType } = useAnnaAppContext();
  const [isSidebarOpened, setSidebarOpened] = useState(
    displayType !== "mobile",
  );

  const handleLogoutClick = useCallback(() => {
    authStore.logout();

    const logoutUrl = authStore.getLogoutUrl();
    window.location.replace(logoutUrl);
  }, [authStore]);

  return (
    <Scaffold
      className={styles.root}
      isSidebarOpened={isSidebarOpened}
      setSidebarOpened={setSidebarOpened}
      footer={
        <ScaffoldFooter>
          {displayType === "tablet" && !isSidebarOpened ? (
            <IconButton
              title="Logout"
              size="l"
              icon="actionSignOut"
              iconColor="secondary900"
              onClick={handleLogoutClick}
            />
          ) : (
            <Button
              className={styles.button}
              onClick={handleLogoutClick}
              text="Logout"
              type="secondary"
              size="l"
              prefix={<Icon type="actionSignOut" color="secondary900" />}
            />
          )}
        </ScaffoldFooter>
      }
      homeUrl="https://anna.money"
      homeTitle="ANNA money"
    >
      {children}
    </Scaffold>
  );
};
