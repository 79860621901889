import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import {
  AuthCapturePage,
  AuthFinishPage,
  AuthStartPage,
} from "./auth/authPages";
import { type IAuthStore } from "./auth/authStore";
import { type IAnalyticsClient } from "./services/analyticsClient";

type UnauthenticatedProps = {
  authStore: IAuthStore;
  analyticsClient: IAnalyticsClient;
};

export const Unauthenticated = observer<UnauthenticatedProps>(
  ({ authStore, analyticsClient }) => (
    <Routes>
      <Route
        path="/auth/finish"
        element={<AuthFinishPage authStore={authStore} />}
      />
      <Route
        path="/auth"
        element={
          <AuthStartPage redirect="/auth/finish" authStore={authStore} />
        }
      />
      <Route
        path="/"
        element={
          <AuthCapturePage
            redirect="/auth"
            authStore={authStore}
            analyticsClient={analyticsClient}
          />
        }
      />
    </Routes>
  ),
);
