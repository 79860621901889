import { makeObservable, override } from "mobx";
import { type IProfileState } from "../../../services/profile/profileState";
import { type IUserState } from "../../../services/user/userState";
import { OnboardingPolicyStore } from "../../policy/onboardingPolicyStore";
import { type OnboardingPolicyStoreApi } from "../../policy/onboardingPolicyStoreApi";

export class PolicyStore extends OnboardingPolicyStore {
  private readonly _userState: IUserState;
  private readonly _profileState: IProfileState;

  constructor(
    api: OnboardingPolicyStoreApi,
    userState: IUserState,
    profileStore: IProfileState,
  ) {
    super(api);
    makeObservable(this, {
      submit: override,
    });
    this._userState = userState;
    this._profileState = profileStore;
  }

  override async submit(): Promise<void> {
    try {
      await super.submit();
      this.submitState = "loading";
      await Promise.all([
        this._profileState.updateProfile(),
        this._userState.updateUserAndAgreements(),
      ]);
      this.submitState = "idle";
    } catch (e) {
      this.submitState = "error";
      throw e;
    }
  }
}
