import {
  AnnaWebChat,
  CardDetailsAction,
  OpenDeepLinkPayloadAction,
  SeeAllVariantsAction,
  UploadIdCardAction,
} from "@anna-money/anna-web-chat";
import { AnnaWebEvidenceWidget } from "@anna-money/anna-web-evidence-widget";
import {
  Autolayout,
  AutolayoutPanel,
  useAnnaAppContext,
} from "@anna-money/anna-web-ui";
import { type MessageHandler } from "@sumsub/websdk";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { type ComponentProps, type FC } from "react";
import { type OnboardingChatStore } from "./onboardingChatStore";
import { CardDetailsPanel } from "./panels/CardDetailsPanel/cardDetailsPanel";
import { IdVerificationPanel } from "./panels/IdVerification/idVerificationPanel";
import { SeeAllVariantsPanel } from "./panels/SeeAllVariantsPanel/seeAllVariantsPanel";
import styles from "./onboardingChat.module.scss";

type Props = {
  className?: string;
  chatProps: ComponentProps<typeof AnnaWebChat>;
  autoLayoutName: string;
  chatAutoLayoutPanelProps?: Partial<ComponentProps<typeof AutolayoutPanel>>;
  onIdVerificationMessage?: MessageHandler;
  chatStore: OnboardingChatStore;
};

export const OnboardingChat: FC<Props> = observer(
  ({
    className,
    chatProps,
    autoLayoutName,
    chatAutoLayoutPanelProps,
    onIdVerificationMessage,
    chatStore,
  }) => {
    const isMobile = useAnnaAppContext().displayType === "mobile";

    return (
      <Autolayout
        name={autoLayoutName}
        className={classNames(styles.root, className)}
      >
        <AutolayoutPanel
          name="chat"
          title="Welcome to ANNA"
          size={isMobile ? "fluid" : "wide"}
          {...chatAutoLayoutPanelProps}
        >
          <AnnaWebChat
            mode="scenario"
            onAction={(action) => {
              chatStore.handleAppAction(action);
            }}
            onReady={chatStore.handleReady}
            {...chatProps}
            className={classNames(styles.chat, chatProps.className)}
          />
        </AutolayoutPanel>
        {chatStore.detailsPanelAction instanceof CardDetailsAction && (
          <CardDetailsPanel
            payload={chatStore.detailsPanelAction.payload}
            onSubmit={chatStore.onCardDetailsSubmit}
            onClose={chatStore.closeDetailsPanel}
            processing={chatStore.isCardDetailsLoading}
          />
        )}
        {chatStore.detailsPanelAction instanceof UploadIdCardAction && (
          <IdVerificationPanel
            payload={chatStore.detailsPanelAction.payload}
            onClose={chatStore.closeDetailsPanel}
            getAccessToken={chatStore.api.getSumSubAccessToken}
            onIdVerificationMessage={onIdVerificationMessage}
          />
        )}
        {chatStore.detailsPanelAction instanceof SeeAllVariantsAction && (
          <SeeAllVariantsPanel
            action={chatStore.detailsPanelAction}
            chatStore={chatStore}
          />
        )}
        {chatStore.detailsPanelAction instanceof OpenDeepLinkPayloadAction && (
          <AnnaWebEvidenceWidget
            href={chatStore.detailsPanelAction.href}
            onClose={chatStore.closeDetailsPanel}
            apiConfig={chatProps.apiConfig}
            authHandler={chatProps.authHandler}
          />
        )}
      </Autolayout>
    );
  },
);
