import { type IAppStore } from "../services/app/appStore";
import { type IAuthClient } from "./authClient";

const accessTokenKey = "anna_access_token";

type AccessTokenPayload = {
  data: string;
};

export interface IAuthenticator {
  isAuthenticated: boolean;
  accessToken: string;
  getAuthUrl: (callbackUrl: URL) => string;
  getLogoutUrl: () => string;
  authenticate: (currentUrl: URL, callbackUrl: URL) => Promise<boolean>;
  logout: () => Promise<void>;
}

/**
 * Gets an access token from api and saves keeps it in localStorage
 */
export class Authenticator implements IAuthenticator {
  private readonly _authClient: IAuthClient;
  private readonly _appStore: IAppStore;

  constructor(authClient: IAuthClient, appStore: IAppStore) {
    this._authClient = authClient;
    this._appStore = appStore;
  }

  get isAuthenticated(): boolean {
    return !!this._loadAccessToken();
  }

  get accessToken(): string {
    const accessToken = this._loadAccessToken();
    if (!accessToken) {
      throw new Error("Unauthenticated");
    }
    return accessToken.data;
  }

  getAuthUrl = (callbackUrl: URL): string => {
    const params = new URLSearchParams({
      client_id: this._appStore.clientId,
      redirect_uri: callbackUrl.toString(),
      screen: "signup",
    });
    return `${this._appStore.config.annaAuthUrl}/authorize?${params.toString()}`;
  };

  getLogoutUrl = (): string => `${this._appStore.config.annaAuthUrl}/logout`;

  async authenticate(currentUrl: URL, callbackUrl: URL): Promise<boolean> {
    const authCode = currentUrl.searchParams.get("code");
    if (!authCode) {
      return false;
    }

    const accessToken = await this._authClient.getAccessToken(
      this._appStore.clientId,
      authCode,
      callbackUrl.toString(),
    );
    this._saveAccessToken({ data: accessToken });
    return true;
  }

  logout = async (): Promise<void> => {
    localStorage.removeItem(accessTokenKey);
    await this._authClient.logout();
  };

  private readonly _saveAccessToken = (
    accessToken: AccessTokenPayload,
  ): void => {
    localStorage.setItem(accessTokenKey, JSON.stringify(accessToken));
  };

  private readonly _loadAccessToken = (): AccessTokenPayload | null => {
    const storageItem = localStorage.getItem(accessTokenKey);
    if (!storageItem) {
      return null;
    }

    try {
      const payload = JSON.parse(storageItem);
      if (
        typeof payload === "object" &&
        "data" in payload &&
        typeof payload.data === "string"
      ) {
        return payload;
      }
      console.error("Access token payload is invalid", payload);
    } catch (e) {
      console.error("Couldn't load access token payload", e);
    }
    return null;
  };
}
