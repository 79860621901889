import { ValidationApiError } from "@anna-money/anna-web-lib";
import { runInAction } from "mobx";
import { OnboardingContinuationMode } from "../../../services/profile/models";
import { type IProfileState } from "../../../services/profile/profileState";

type State = "idle" | "loading" | Error;

export class ChoiceStore {
  private readonly _profileState: IProfileState;
  private _continueHereState: State = "idle";
  private _continueInAppState: State = "idle";

  constructor(profileState: IProfileState) {
    this._profileState = profileState;
  }

  get continueHereState(): State {
    return this._continueHereState;
  }

  set continueHereState(state: State) {
    this._continueHereState = state;
  }

  get continueInAppState(): State {
    return this._continueInAppState;
  }

  set continueInAppState(state: State) {
    this._continueInAppState = state;
  }

  error(): Error | null {
    if (this.continueHereState instanceof Error) {
      return this.continueHereState;
    }
    return this.continueInAppState instanceof Error
      ? this.continueInAppState
      : null;
  }

  handleContinueHere = async (): Promise<void> => {
    await this._handleContinuationMode(
      OnboardingContinuationMode.web,
      "continueHereState",
    );
  };

  handleContinueInApp = async (): Promise<void> => {
    await this._handleContinuationMode(
      OnboardingContinuationMode.mobile,
      "continueInAppState",
    );
  };

  private _handleContinuationMode = async (
    mode: OnboardingContinuationMode,
    stateName: "continueHereState" | "continueInAppState",
  ): Promise<void> => {
    this.continueHereState = "idle";
    this.continueInAppState = "idle";

    this[stateName] = "loading";
    try {
      await runInAction(async () => {
        this._profileState.profile.onboardingContinuationMode = mode;
        await this._profileState.setProfile();
        await this._profileState.activate();
      });
    } catch (e) {
      if (e instanceof ValidationApiError) {
        this[stateName] = e;
      } else {
        this[stateName] = new Error("Something went wrong. Please try again");
      }
      console.error(e);
    }
  };
}
