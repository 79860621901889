import {
  type CountryCode,
  formatIncompletePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";

/**
 * First try to interpret a phone number as a local GB number,
 * then use the default pattern with + and a country code at the beginning
 */
export const defaultCountryCode = (
  phoneNumber: string,
): CountryCode | undefined => {
  return phoneNumber.startsWith("+") ? undefined : "GB";
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  return isValidPhoneNumber(phoneNumber, defaultCountryCode(phoneNumber));
};

export const format = (phoneNumber: string): string => {
  return formatIncompletePhoneNumber(
    phoneNumber,
    defaultCountryCode(phoneNumber),
  );
};
