import { AnnaError } from "@anna-money/anna-web-lib";
import {
  OnboardingMode,
  type Profile,
  ProfileKind,
} from "../services/profile/models";
import { type User } from "../services/user/models";
import { type ClassicSurveyStep } from "./surveyStep";

export const getClassicStep = (
  user: User,
  profile: Profile,
): ClassicSurveyStep => {
  const { onboardingMode } = profile;

  if (
    ![OnboardingMode.classic, OnboardingMode.classicWithOptionalChat].includes(
      onboardingMode,
    )
  ) {
    throw new AnnaError("Call getInitial step with wrong onboarding mode", {
      extra: { onboardingMode },
    });
  }

  /**
   * The policy and the phone steps are required for everyone.
   * Other steps can be passed here, in the web, unless the profile is activated.
   * Otherwise, if profile was activated, a user sees the 'continue in app' step and will pass the rest steps in the mobile app
   */
  if (!user.isPolicyAccepted) {
    return "policy";
  }

  if (!user.isPhoneNumberVerified) {
    return "phone";
  }

  if (profile.activated) {
    return "continue-in-app";
  }

  if (!profile.firstName || !profile.lastName) {
    return "fullname";
  }

  if (!profile.dateOfBirth) {
    return "dob";
  }

  if (
    !profile.homeAddress?.houseNameOrNumber ||
    !profile.homeAddress.street ||
    !profile.homeAddress.town ||
    !profile.homeAddress.postcode
  ) {
    return "address";
  }

  if (!profile.entityType) {
    return "kind";
  }

  if (
    profile.entityType === ProfileKind.soleTrader &&
    (!profile.soleTrader ||
      (!profile.soleTrader.useOwnName && !profile.soleTrader.tradingName))
  ) {
    return "sole-trading-name";
  }

  if (
    profile.entityType === ProfileKind.company &&
    !profile.company?.companyNumber
  ) {
    return "company-number";
  }

  if (
    profile.onboardingMode === OnboardingMode.classicWithOptionalChat &&
    !profile.onboardingContinuationMode
  ) {
    return "choice";
  }

  return "continue-in-app";
};
