import { createContext, useContext, useRef } from "react";
import { type Services } from "./services";

export const ServicesContext = createContext<Services | null>(null);

export const useServices = (): Services => {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error("Services must be requested in ServicesContext context");
  }
  return context;
};

export function useServiceFactory<T>(factory: (stores: Services) => T): T {
  const store = useServices();
  return useRef(factory(store)).current;
}
