import { observer } from "mobx-react-lite";
import { type ReactElement, useEffect } from "react";
import { useStepperContext } from "../../../components/stepper/context";
import { sendOnboardingStart } from "../../../utils/events";
import { type PhoneProps } from "../../phone/interfaces";
import { useServices } from "../../services/servicesContext";

type PhoneStepProps = {
  children: (props: PhoneProps) => ReactElement;
};

export const PhoneStep = observer<PhoneStepProps>(({ children }) => {
  const { phoneStore } = useServices();
  const { navigateTo } = useStepperContext<"phone-otp">();

  const handleSubmit = async (formattedValue: string): Promise<void> => {
    try {
      await phoneStore.savePhoneNumber(formattedValue);
      navigateTo("phone-otp");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    sendOnboardingStart();
  }, []);

  return children({ state: phoneStore.savePhoneState, onSubmit: handleSubmit });
});
