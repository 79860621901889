import { Button, Text, TextButton } from "@anna-money/anna-web-ui";
import {
  type ChangeEvent,
  type FC,
  type FormEventHandler,
  useRef,
} from "react";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepDescription } from "../../components/stepper/description";
import { StepTitle } from "../../components/stepper/title";
import { TextInput } from "../../components/text-input";
import { type PhoneOtpProps } from "./interfaces";

type Props = {
  mode?: "classic" | "vertical";
  styles: Record<string, string>;
} & PhoneOtpProps;

export const phoneOtpTestIds = {
  input: "phone-otp-input",
  submitButton: "submit-button",
  resendOtpButton: "resend-button",
  changePhoneNumberButton: "change-phone-number-button",
};

export const OnboardingPhoneOtpBasic: FC<Props> = ({
  styles,
  mode = "classic",
  value,
  phoneNumber,
  onChange,
  onSubmit,
  onPrevClick,
  state,
  onOTPClick,
  restOtpSeconds,
  testId,
}) => {
  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };
  const isLoading = state === "loading";

  const resendOTPBtn = (
    <TextButton
      size={mode === "vertical" ? "l" : undefined}
      text="Resend one-time code"
      disabled={isLoading || restOtpSeconds > 0}
      onClick={onOTPClick}
      testId={phoneOtpTestIds.resendOtpButton}
    />
  );
  const changePhoneNumberButton = (
    <TextButton
      className={styles.changePhoneNumberButton}
      size={mode === "vertical" ? "l" : undefined}
      text="Change phone number"
      disabled={isLoading}
      onClick={onPrevClick}
      testId={phoneOtpTestIds.changePhoneNumberButton}
    />
  );

  return (
    <form onSubmit={handleSubmit} className={styles.root} data-test-id={testId}>
      <StepTitle className={styles.title}>
        Great - we’ve sent a text message to {phoneNumber},
        <br />
        please type in your one-time-code below
      </StepTitle>

      <StepDescription className={styles.subTitle}>
        It should be in a format like this: 000000.
      </StepDescription>

      <TextInput
        className={styles.input}
        size={mode === "vertical" ? "l" : undefined}
        name="phone-otp"
        value={value}
        label="Enter the code from your SMS"
        newDesign={mode === "vertical"}
        inputProps={{
          placeholder: "000000",
          testId: phoneOtpTestIds.input,
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
      />

      {restOtpSeconds > 0 && (
        <Text type="body4" className={styles.restOtpText}>
          You can request a new code in {restOtpSeconds}
        </Text>
      )}

      <div className={styles.buttons}>
        {changePhoneNumberButton}
        {resendOTPBtn}
      </div>

      <Button
        size={mode === "vertical" ? "l" : undefined}
        className={styles.button}
        type="primary"
        text={isLoading ? "Loading..." : "Next"}
        disabled={isLoading}
        onClick={handleNextClick}
        testId={phoneOtpTestIds.submitButton}
      />

      {state instanceof Error && (
        <Text color="themeTerracotta">{state.message}</Text>
      )}

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
};
