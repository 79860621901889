import { HttpClient, type HttpHandler } from "@anna-money/anna-web-lib";
import { Memoize } from "typescript-memoize";
import { SentryHost } from "logging/sentryHost";
import type { IApiClient } from "../../api/apiClient";
import type { IAuthStore } from "../../auth/authStore";
import { ChatStore } from "../../chatSurvey/steps/chat/chatStore";
import { ChatStoreApi } from "../../chatSurvey/steps/chat/chatStoreApi";
import type { IAnalyticsClient } from "../../services/analyticsClient";
import type { IAppStore } from "../../services/app/appStore";
import {
  type IProfileApiClient,
  ProfileApiClient,
} from "../../services/profile/api";
import type { IProfileState } from "../../services/profile/profileState";
import { UserApiClient } from "../../services/user/api";
import type { IUserState } from "../../services/user/userState";
import type { OnboardingChatStoreApi } from "../chat/onboardingChatStoreApi";
import { PhoneStore } from "../containers/phone/phoneStore";
import { PhoneStoreApi } from "../containers/phone/phoneStoreApi";
import { PolicyStore } from "../containers/policy/policyStore";
import { PolicyStoreApi } from "../containers/policy/policyStoreApi";
import { OnboardingPhoneStore } from "../phone/onboardingPhoneStore";
import type { OnboardingPhoneStoreApi } from "../phone/onboardingPhoneStoreApi";
import type { OnboardingPolicyStoreApi } from "../policy/onboardingPolicyStoreApi";

export class Services {
  constructor(
    readonly apiClient: IApiClient,
    readonly appStore: IAppStore,
    readonly userState: IUserState,
    readonly userApiClient: UserApiClient,
    readonly profileState: IProfileState,
    readonly analyticsClient: IAnalyticsClient,
    readonly authStore: IAuthStore,
    readonly authHandler: HttpHandler,
    readonly sentryHost: SentryHost,
  ) {}

  @Memoize()
  get httpClient(): HttpClient {
    return new HttpClient(this.appStore.config.annaApiUrl, [this.authHandler]);
  }

  @Memoize()
  get policyStoreApi(): OnboardingPolicyStoreApi {
    return new PolicyStoreApi(this.userApiClient);
  }

  @Memoize()
  get phoneStoreApi(): OnboardingPhoneStoreApi {
    return new PhoneStoreApi(this.userApiClient);
  }

  @Memoize()
  get chatStoreApi(): OnboardingChatStoreApi {
    return new ChatStoreApi(this.apiClient, this.httpClient);
  }

  @Memoize()
  get policyStore(): PolicyStore {
    return new PolicyStore(
      this.policyStoreApi,
      this.userState,
      this.profileState,
    );
  }

  @Memoize()
  get phoneStore(): OnboardingPhoneStore {
    return new PhoneStore(this.phoneStoreApi, this.userState);
  }

  @Memoize()
  get chatStore(): ChatStore {
    return new ChatStore(this.chatStoreApi, this.profileState);
  }

  @Memoize()
  get profileApiClient(): IProfileApiClient {
    return new ProfileApiClient(this.apiClient);
  }
}
