import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class AddressSuggestion {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly addressText: string,
  ) {}
}

@Serializable()
export class Address {
  constructor(
    @JsonProperty({ required: false, type: String }) readonly id?: string,
    @JsonProperty({ required: false, type: String }) readonly locality?: string,
    @JsonProperty({ required: false, type: String })
    readonly houseNameOrNumber?: string,
    @JsonProperty({ required: false, type: String }) readonly postcode?: string,
    @JsonProperty({ required: false, type: String }) readonly street?: string,
    @JsonProperty({ required: false, type: String }) readonly town?: string,
    @JsonProperty({ required: false, type: String }) readonly county?: string,
    @JsonProperty({ required: false, type: String, name: "address_line_1" })
    readonly addressLineOne?: string,
  ) {}
}
