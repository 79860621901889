import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class InitializePhoneNumberChange {
  constructor(@JsonProperty() readonly correlationId: string) {}
}

@Serializable()
export class SavePhoneNumber {
  constructor(@JsonProperty() readonly resendDelay: number) {}
}
