import { type FC } from "react";
import { AutoLayoutStep } from "../../common/autoLayoutStep/autolayoutStep";
import { PhoneStep } from "../../common/containers/phone/phone";
import { OnboardingPhone } from "../../common/phone/phoneChat/onboardingPhone";

export const phoneStepTestIds = {
  root: "phone-step",
};

export const ChatPhoneStep: FC = () => (
  <PhoneStep>
    {(props) => (
      <AutoLayoutStep name="phone">
        <OnboardingPhone {...props} testId={phoneStepTestIds.root} />
      </AutoLayoutStep>
    )}
  </PhoneStep>
);
