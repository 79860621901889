import "reflect-metadata";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import loadConfig from "./common/loadConfig";
import reportWebVitals from "./reportWebVitals";
import { AppStore } from "./services/app/appStore";

dayjs.extend(relativeTime);

void loadConfig().then((config) => {
  if (!config) {
    return;
  }

  const appStore = new AppStore(config);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <App appStore={appStore} />
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
