import { type UserApiClient } from "../../../services/user/api";
import {
  type InitializePhoneNumberChange,
  type SavePhoneNumber,
} from "../../phone/onboardingPhoneModels";
import { type OnboardingPhoneStoreApi } from "../../phone/onboardingPhoneStoreApi";

export class PhoneStoreApi implements OnboardingPhoneStoreApi {
  constructor(private readonly _userApiClient: UserApiClient) {}

  initializePhoneNumberChange =
    async (): Promise<InitializePhoneNumberChange> => {
      return await this._userApiClient.apiInitializePhoneNumberChange();
    };

  savePhoneNumber = async (
    phoneNumber: string,
    correlationId: string,
  ): Promise<SavePhoneNumber> => {
    return await this._userApiClient.apiSavePhoneNumber(
      phoneNumber,
      correlationId,
    );
  };

  confirmPhoneNumber = async (
    code: string,
    correlationId: string,
  ): Promise<void> => {
    await this._userApiClient.apiConfirmPhoneNumber(code, correlationId);
  };

  resendOTP = async (
    correlationId: string,
  ): Promise<{ resendDelay: number }> => {
    return await this._userApiClient.apiResendOTP(correlationId);
  };
}
