import { observer } from "mobx-react-lite";
import { type ReactElement } from "react";
import { useStepperContext } from "../../../components/stepper/context";
import { type PolicyProps } from "../../policy/interfaces";
import { useServices } from "../../services/servicesContext";

type PolicyStepProps = {
  children: (props: PolicyProps) => ReactElement;
};

export const PolicyStep = observer<PolicyStepProps>(({ children }) => {
  const { policyStore } = useServices();
  const { navigateNext } = useStepperContext();

  const handleSubmit = async (): Promise<void> => {
    try {
      await policyStore.submit();
      navigateNext();
    } catch (e) {
      console.log(e);
    }
  };

  return children({ store: policyStore, onSubmit: handleSubmit });
});
