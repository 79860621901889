import { Button, Text, TextButton } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import {
  type ChangeEvent,
  type FormEventHandler,
  useMemo,
  useRef,
  useState,
} from "react";
import { type IApiClient } from "../../api/apiClient";
import { type WebOnboardingNestedValidationError } from "../../api/validationError";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { TextInput } from "../../components/text-input";
import { Address, type AddressSuggestion } from "../../domain/address";
import { ProfileHomeAddress } from "../../services/profile/models";
import { ProfileValidationError } from "../../services/profile/profileState";
import { AddressSelect } from "./components/address-select";

const testIds = {
  root: "address-step",
  manualAddressButton: "manual-address-button",
  addressName: "address-name",
  street: "street",
  town: "town",
  postcode: "postcode",
  submitButton: "submit-button",
};

export const AddressStep = observer(() => {
  const { profileState, apiClient } = useServices();
  const { profile, setAddress } = profileState;

  const { navigateTo, navigateNext } = useStepperContext();

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const [showErrors, setShowErrors] = useState(false);

  const [houseNameNumber, setHouseNameNumber] = useState(
    profile.homeAddress?.houseNameOrNumber ?? "",
  );
  const houseNameNumberInvalid = useMemo(
    () => !houseNameNumber.trim(),
    [houseNameNumber],
  );

  const [street, setStreet] = useState(profile.homeAddress?.street ?? "");
  const streetInvalid = useMemo(() => !street.trim(), [street]);

  const [locality, setLocality] = useState(profile.homeAddress?.locality ?? "");

  const [town, setTown] = useState(profile.homeAddress?.town ?? "");
  const townInvalid = useMemo(() => !town.trim(), [town]);

  const [county, setCounty] = useState(profile.homeAddress?.county ?? "");

  const [postcode, setPostcode] = useState(profile.homeAddress?.postcode ?? "");
  const [validationErrors, setValidationErrors] = useState<
    WebOnboardingNestedValidationError[]
  >([]);
  const postcodeIsEmpty = useMemo(() => !postcode.trim(), [postcode]);
  const postcodeInvalid =
    postcodeIsEmpty || validationErrors.some(({ id }) => id === "postcode");

  const [detailsExpanded, setDetailsExpanded] = useState<boolean>(
    Boolean(
      houseNameNumber || street || locality || town || county || postcode,
    ),
  );

  const handleSelectAddress = async (
    address: AddressSuggestion | null,
  ): Promise<void> => {
    if (!address) {
      return;
    }

    try {
      const profileAddress = await resolveAddress(address.id, apiClient);

      setHouseNameNumber(profileAddress.houseNameOrNumber ?? "");

      setStreet(profileAddress.street ?? "");

      setLocality(profileAddress.locality ?? "");

      setTown(profileAddress.town ?? "");

      setCounty(profileAddress.county ?? "");

      setPostcode(profileAddress.postcode ?? "");

      setShowErrors(false);

      setDetailsExpanded(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (
      houseNameNumberInvalid ||
      streetInvalid ||
      townInvalid ||
      postcodeIsEmpty
    ) {
      setShowErrors(true);
      return;
    }

    setValidationErrors([]);
    setShowErrors(false);

    try {
      await setAddress(
        new ProfileHomeAddress(
          houseNameNumber,
          street,
          locality,
          town,
          county,
          postcode,
        ),
      );
      navigateNext();
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setValidationErrors(e.nestedErrors);
        setShowErrors(true);
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("dob");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} data-test-id={testIds.root}>
      <StepWrapper>
        <StepTitle>What’s your home address?</StepTitle>

        <StepDescription>
          So we know where to send your new card.
        </StepDescription>

        <div>
          <AddressSelect onSelect={handleSelectAddress} />

          {!detailsExpanded && (
            <>
              <Gap />

              <TextButton
                text="Manually enter address"
                onClick={() => {
                  setDetailsExpanded(true);
                }}
                testId={testIds.manualAddressButton}
              />
            </>
          )}

          {detailsExpanded && (
            <>
              <Gap $size={2} />

              <TextInput
                name="address-name"
                value={houseNameNumber}
                label="Address name / Number *"
                error="You must enter your building name or number"
                hasError={showErrors && houseNameNumberInvalid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setHouseNameNumber(e.target.value);
                }}
                inputProps={{
                  testId: testIds.addressName,
                }}
              />

              <Gap $size={2} />

              <TextInput
                name="street"
                value={street}
                label="Street *"
                error="You must enter a street name"
                hasError={showErrors && streetInvalid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setStreet(e.target.value);
                }}
                inputProps={{
                  testId: testIds.street,
                }}
              />

              <Gap $size={2} />

              <TextInput
                name="locality"
                value={locality}
                label="Locality"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLocality(e.target.value);
                }}
              />

              <Gap $size={2} />

              <TextInput
                name="town"
                value={town}
                label="Town *"
                error="You must enter a town name"
                hasError={showErrors && townInvalid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTown(e.target.value);
                }}
                inputProps={{
                  testId: testIds.town,
                }}
              />

              <Gap $size={2} />

              <TextInput
                name="county"
                value={county}
                label="County"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCounty(e.target.value);
                }}
              />

              <Gap $size={2} />

              <TextInput
                name="postcode"
                value={postcode}
                label="Postcode *"
                error={
                  validationErrors.find(({ id }) => id === "postcode")?.title ||
                  "You must enter a valid postcode"
                }
                hasError={showErrors && postcodeInvalid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPostcode(e.target.value);
                }}
                inputProps={{
                  testId: testIds.postcode,
                }}
              />
            </>
          )}
        </div>

        <StepActions>
          <SecondaryActionButton text="Back" onClick={handlePrevClick} />
          <Button
            type="primary"
            text="Next"
            onClick={handleNextClick}
            testId={testIds.submitButton}
          />
        </StepActions>

        {showErrors && !detailsExpanded && (
          <Text color="themeTerracotta">
            You must enter an address to proceed
          </Text>
        )}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});

async function resolveAddress(
  id: string,
  apiClient: IApiClient,
): Promise<Address> {
  const result = await apiClient.get(
    `api/web-onboarding/references/addresses/${id}`,
  );

  return result.getData(Address);
}
