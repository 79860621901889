import { makeObservable, override } from "mobx";
import { OnboardingPhoneStore } from "common/phone/onboardingPhoneStore";
import { type IUserState } from "../../../services/user/userState";
import { type OnboardingPhoneStoreApi } from "../../phone/onboardingPhoneStoreApi";

export class PhoneStore extends OnboardingPhoneStore {
  private readonly _userState: IUserState;

  constructor(api: OnboardingPhoneStoreApi, userState: IUserState) {
    super(api);
    makeObservable(this, {
      savePhoneNumber: override,
      confirmPhoneNumber: override,
    });
    this._userState = userState;
  }

  override async savePhoneNumber(phoneNumber: string): Promise<void> {
    await super.savePhoneNumber(phoneNumber);

    this._userState.setUnverifiedPhoneNumber(phoneNumber);
  }

  override async confirmPhoneNumber(code: string): Promise<void> {
    await super.confirmPhoneNumber(code);

    await this._userState.updateUser();
  }
}
