import "@anna-money/anna-web-ui/dist/index.css";
import { ApiClient } from "@anna-money/anna-web-lib";
import { AnnaApp, WindowManager } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { serializer } from "common/serializer";
import { SentryHost } from "logging/sentryHost";
import { AuthClient } from "./auth/authClient";
import { AuthHandler } from "./auth/authHandler";
import { AuthStore } from "./auth/authStore";
import { Authenticator } from "./auth/authenticator";
import { Authenticated } from "./authenticated";
import { AnalyticsClient } from "./services/analyticsClient";
import { type IAppStore } from "./services/app/appStore";
import { UserApiClient } from "./services/user/api";
import { UserStore } from "./services/user/userStore";
import { Unauthenticated } from "./unauthenticated";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    background-color: white !important;
  }

  #root {
    height: 100%;
  }

  a {
    color: #4e4e4e;
  }
`;

export const App = observer<{ appStore: IAppStore }>(({ appStore }) => {
  const authClient = useRef(new AuthClient(appStore.config)).current;
  const authenticator = useRef(new Authenticator(authClient, appStore)).current;
  const authStore = useRef(new AuthStore(authenticator)).current;
  const authHandler = useRef(new AuthHandler(authenticator, authStore)).current;
  const analyticsClient = useRef(
    new AnalyticsClient(appStore.config.annaApiUrl),
  ).current;
  const apiClient = useRef(
    new ApiClient(appStore.config.annaApiUrl, serializer, [authHandler]),
  ).current;
  const userApiClient = useRef(new UserApiClient(apiClient)).current;
  const userStore = useRef(new UserStore(userApiClient)).current;
  const sentryHost = useRef(new SentryHost(appStore, userStore)).current;

  return (
    <AnnaApp>
      <WindowManager>
        <GlobalStyle />

        <BrowserRouter>
          {authStore.state === "authenticated" ? (
            <Authenticated
              apiClient={apiClient}
              userApiClient={userApiClient}
              userStore={userStore}
              sentryHost={sentryHost}
              analyticsClient={analyticsClient}
              appStore={appStore}
              authStore={authStore}
              authHandler={authHandler}
            />
          ) : (
            <Unauthenticated
              analyticsClient={analyticsClient}
              authStore={authStore}
            />
          )}
        </BrowserRouter>
      </WindowManager>
    </AnnaApp>
  );
});
