import { Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useServices } from "../../common/services/servicesContext";
import { CompanyNumberAutoStep } from "./company-number-auto";
import { CompanyNumberSearchStep } from "./company-number-search";

export const CompanyNumberStep = observer(() => {
  const { profileState } = useServices();
  const {
    companiesByOfficer,
    loadingCompaniesByOfficer,
    getCompaniesByOfficer,
  } = profileState;

  const [showSearch, setShowSearch] = useState(false);

  const view = !companiesByOfficer?.length || showSearch ? "search" : "auto";

  useEffect(() => {
    void getCompaniesByOfficer();
  }, [getCompaniesByOfficer]);

  if (!companiesByOfficer || loadingCompaniesByOfficer) {
    return <Text>Loading...</Text>;
  }

  if (view === "auto") {
    return (
      <CompanyNumberAutoStep
        companies={companiesByOfficer}
        loadingCompanies={loadingCompaniesByOfficer}
        showSearch={() => {
          setShowSearch(true);
        }}
      />
    );
  }

  return <CompanyNumberSearchStep />;
});
