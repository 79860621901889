import { type FC } from "react";
import { AutoLayoutStep } from "../../common/autoLayoutStep/autolayoutStep";
import { PolicyStep } from "../../common/containers/policy/policyStep";
import { OnboardingPolicy } from "../../common/policy/onboardingPolicy";

export const chatPolicyStepTestIds = {
  root: "policy-step",
};

export const ChatPolicyStep: FC = () => (
  <PolicyStep>
    {(props) => (
      <AutoLayoutStep name="policy">
        <OnboardingPolicy {...props} testId={chatPolicyStepTestIds.root} />
      </AutoLayoutStep>
    )}
  </PolicyStep>
);
