import { Radio as RadioComponent, Text } from "@anna-money/anna-web-ui";
import React, {
  type ComponentProps,
  type FC,
  type FunctionComponent,
} from "react";
import styled from "styled-components";
import { Gap } from "../../../components/gap";
import { VerticalRadioGroup } from "../../../components/vertical-radio-group";
import { type Company } from "../../../services/profile/models";

type RadioComponentProps = ComponentProps<typeof RadioComponent>;

const Radio: FunctionComponent<RadioComponentProps & { required?: boolean }> =
  styled(RadioComponent)``;

const ErrorText = styled(Text).attrs({
  type: "body4",
  color: "themeTerracotta",
})`
  margin-left: 24px;
  display: block;
`;

interface CompanyListProps {
  list: Array<Pick<Company, "companyNumber" | "title" | "status">>;
  value?: string;
  onChange: (value?: string) => void;
}

export const CompanyList: FC<CompanyListProps> = ({
  list,
  value,
  onChange,
}) => (
  <VerticalRadioGroup
    name="company-list"
    value={value}
    onChange={(e) => {
      onChange((e as any).target.value);
    }}
  >
    {list.map(({ companyNumber, title, status }, i) => (
      <React.Fragment key={companyNumber}>
        <Radio value={companyNumber} label={title} required />
        {companyNumber === value && status !== "ACTIVE" && (
          <ErrorText>
            Sorry. We can't open an account for your business
            <br /> as it doesn't comply with our Terms & Conditions
          </ErrorText>
        )}
        {i < list.length - 1 && <Gap />}
      </React.Fragment>
    ))}
  </VerticalRadioGroup>
);
