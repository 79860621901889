import { observer } from "mobx-react-lite";
import { type ComponentType, type FC } from "react";
import { useAnalyticsStepView } from "../common/hooks";
import { useServices } from "../common/services/servicesContext";
import { Stepper } from "../components/stepper/stepper";
import { type ChatSurveyStep } from "./chatSurveyStep";
import { getChatSurveyStep } from "./getChatSurveyStep";
import { ChatSurveyScaffold } from "./scaffold/chatSurveyScaffold";
import { ChatStep } from "./steps/chat/chat";
import { ChatPhoneStep } from "./steps/phone";
import { ChatPhoneOtpStep } from "./steps/phoneOtp";
import { ChatPolicyStep } from "./steps/policy";
import styles from "./chatSurvey.module.scss";
import "@anna-money/anna-web-ui/dist/theme2.scss";

const STEPS: Record<ChatSurveyStep, ComponentType> = {
  policy: ChatPolicyStep,
  phone: ChatPhoneStep,
  "phone-otp": ChatPhoneOtpStep,
  chat: ChatStep,
};

const ChatSurveyStepView: FC<{
  step: ChatSurveyStep;
  content: ComponentType;
}> = ({ step, content: Content }) => {
  useAnalyticsStepView(step);

  return <Content />;
};

export const ChatSurvey: FC = observer(() => {
  const { userState, profileState } = useServices();

  return (
    <div className={styles.root}>
      <Stepper
        steps={STEPS}
        getStep={getChatSurveyStep}
        initialStep={getChatSurveyStep(userState.user, profileState.profile)}
      >
        {(content, step) => (
          <ChatSurveyScaffold>
            <ChatSurveyStepView step={step} content={content} />
          </ChatSurveyScaffold>
        )}
      </Stepper>
    </div>
  );
});
