import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { AuthLogoutPage } from "./auth/authPages";
import { ChatSurvey } from "./chatSurvey/chatSurvey";
import { ClassicSurvey } from "./classicSurvey/classicSurvey";
import { useServices } from "./common/services/servicesContext";
import {
  OnboardingContinuationMode,
  OnboardingMode,
} from "./services/profile/models";

export const BootstrappedApp = observer(() => {
  const { authStore, profileState } = useServices();
  const { onboardingMode, onboardingContinuationMode } = profileState.profile;

  const isChatSurvey =
    onboardingMode === OnboardingMode.chat ||
    (onboardingMode === OnboardingMode.classicWithOptionalChat &&
      onboardingContinuationMode === OnboardingContinuationMode.web);

  return (
    <Routes>
      <Route
        path="/auth/logout"
        element={<AuthLogoutPage authStore={authStore} />}
      />
      <Route
        path="/"
        element={isChatSurvey ? <ChatSurvey /> : <ClassicSurvey />}
      />
    </Routes>
  );
});
