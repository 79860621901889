import { type AppAction } from "@anna-money/anna-web-chat";
import { AutolayoutPanel, Button, TextButton } from "@anna-money/anna-web-ui";
import { type ReactElement } from "react";
import styles from "./detailsPanel.module.scss";

type Props<A extends AppAction> = {
  name: string;
  title: string;
  children: ReactElement<{ action: A }>;
  showButton?: boolean;
  submit?: {
    processing?: boolean;
    onSubmit: () => void | Promise<void>;
    buttonText: string;
    buttonTestId?: string;
  };
  processing?: boolean;
  onClose: () => void;
};

export const DetailsPanel = <A extends AppAction>({
  children,
  name,
  title,
  submit,
  showButton = true,
  onClose,
}: Props<A>): ReactElement => {
  const closeProps = {
    text: "Close",
    onClick: () => {
      onClose();
    },
  };
  const submitProps = submit
    ? {
        processing: submit.processing,
        onClick: submit.onSubmit,
        text: submit.buttonText,
        testId: submit.buttonTestId,
      }
    : undefined;

  const bottom = showButton ? (
    <div className={styles.submitButtonWrapper}>
      <Button
        className={styles.submitButton}
        size="l"
        {...(submitProps ?? closeProps)}
      />
    </div>
  ) : null;
  const titleSuffix = (
    <TextButton
      size="m"
      text="Close"
      className={styles.closeButton}
      onClick={onClose}
    />
  );

  return (
    <AutolayoutPanel
      name={name}
      title={title}
      size="fluid"
      bottomContent={bottom}
      titleSuffix={titleSuffix}
    >
      <div className={styles.children}>{children}</div>
    </AutolayoutPanel>
  );
};
