import { type ChangeEvent, type FC } from "react";
import * as S from "./cardDetailsForm.styles";

type Props = {
  name: string;
  onNameChange: (value: string) => void;
  company: string;
  onCompanyChange: (value: string) => void;
  maxLen: number;
};

export const CardDetailsForm: FC<Props> = ({
  name,
  onNameChange,
  company,
  onCompanyChange,
  maxLen,
}) => {
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onNameChange(e.target.value.toUpperCase());
  };
  const handleCompanyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onCompanyChange(e.target.value.toUpperCase());
  };

  return (
    <S.Root>
      <S.Header>
        <div>
          <S.AnnaLogo />
          <S.CardTypeText weight="bold">business</S.CardTypeText>&nbsp;
          <S.CardTypeText>debit</S.CardTypeText>&nbsp;
          <S.CardTypeText weight="bold">world</S.CardTypeText>
        </div>
        <S.MCLogo />
      </S.Header>
      <S.Content>
        <S.InputBlock>
          <S.Input
            label="Cardholder name"
            name="cardDetailsName"
            inputProps={{
              placeholder: "e.g. ALEXANDER HOLMES",
              max: maxLen,
            }}
            value={name}
            onChange={handleNameChange}
          />
          <S.InputCounter>
            {name.length} / {maxLen}
          </S.InputCounter>
        </S.InputBlock>
        <S.InputBlock>
          <S.Input
            label="Company name (optional)"
            name="cardDetailsCompany"
            inputProps={{
              placeholder: "e.g. ANNA LTD",
              max: maxLen,
            }}
            value={company}
            onChange={handleCompanyChange}
          />
          <S.InputCounter>
            {company.length} / {maxLen}
          </S.InputCounter>
        </S.InputBlock>
      </S.Content>
    </S.Root>
  );
};
