import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { type PolicyProps } from "./interfaces";
import { OnboardingPolicyClassic } from "./onboardingPolicyClassic";
import styles from "./policy.module.scss";

export const OnboardingPolicy = observer<PolicyProps>(
  ({ className, store, onSubmit, testId }) => {
    return (
      <OnboardingPolicyClassic
        className={classNames(styles.rootVertical, className)}
        checkboxSize="l"
        buttonSize="l"
        store={store}
        onSubmit={onSubmit}
        testId={testId}
      />
    );
  },
);
