import {
  type AnnaWebChatInteractor,
  type AppAction,
  CardDetailsAction,
  OpenDeepLinkPayloadAction,
  SeeAllVariantsAction,
  UploadIdCardAction,
} from "@anna-money/anna-web-chat";
import { type Constructor } from "@anna-money/anna-web-lib";
import { action, computed, makeObservable, observable } from "mobx";
import { type OnboardingChatStoreApi } from "./onboardingChatStoreApi";

export class OnboardingChatStore<
  T extends OnboardingChatStoreApi = OnboardingChatStoreApi,
> {
  protected _detailsPanelAction: AppAction | null = null;
  protected _chatInteractor: AnnaWebChatInteractor | null = null;
  protected readonly supportedAppActions: Array<Constructor<AppAction>> = [
    CardDetailsAction,
    UploadIdCardAction,
    SeeAllVariantsAction,
    OpenDeepLinkPayloadAction,
  ];

  isCardDetailsLoading = false;

  constructor(readonly api: T) {
    makeObservable(this, {
      _detailsPanelAction: observable,
      isCardDetailsLoading: observable,
      detailsPanelAction: computed,
      handleAppAction: action,
      closeDetailsPanel: action,
      onCardDetailsSubmit: action,
      init: action,
    } as any);
  }

  get detailsPanelAction(): AppAction | null {
    return this._detailsPanelAction;
  }

  get chatInteractor(): AnnaWebChatInteractor | null {
    return this._chatInteractor;
  }

  handleAppAction(action: AppAction): void {
    if (this.supportedAppActions.some((a) => action instanceof a)) {
      this._detailsPanelAction = action;
    }
  }

  closeDetailsPanel = (): void => {
    this._detailsPanelAction = null;
  };

  handleReady = (chatInteractor: AnnaWebChatInteractor): void => {
    this._chatInteractor = chatInteractor;
    void this.init();
  };

  onCardDetailsSubmit = async (data: {
    name: string;
    company: string;
  }): Promise<void> => {
    this.isCardDetailsLoading = true;
    try {
      await this.api.saveCardDetails(data);
      this.closeDetailsPanel();
    } finally {
      this.isCardDetailsLoading = false;
    }
  };

  async init(): Promise<void> {
    await this.api.onLogin();
  }
}
