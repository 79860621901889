import { type Forms } from "@anna-money/anna-web-ui";
import {
  type ChangeEventHandler,
  type ComponentProps,
  type FC,
  type FocusEventHandler,
  useRef,
  useState,
} from "react";
import { format, isPhoneNumberValid } from "./phone-helpers";
import { TextInput } from "./text-input";

type Props = {
  className?: string;
  name: string;
  value: string;
  onChange?: (value: string) => void;
  onValidityStateChange?: (valid: boolean) => void;
  inputSize?: ComponentProps<typeof Forms.TextInput>["size"];
  inputTestId?: string;
  error?: string;
};

export const PhoneField: FC<Props> = ({
  className,
  name,
  value,
  onChange,
  onValidityStateChange,
  inputSize,
  inputTestId,
  error,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const everChangedRef = useRef(false);

  const validateValueAndUpdateValidityState = (
    value: string,
    silent: boolean = false,
  ): void => {
    const isValid = isPhoneNumberValid(value);

    onValidityStateChange?.(isValid);

    if (!silent) {
      setIsInvalid(!isValid);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    everChangedRef.current = true;

    if (e.defaultPrevented) {
      return;
    }

    onChange?.(format(e.target.value));

    validateValueAndUpdateValidityState(e.target.value, !isInvalid);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (e.isDefaultPrevented()) {
      return;
    }

    if (!e.target.value && !everChangedRef.current) {
      return;
    }

    validateValueAndUpdateValidityState(e.target.value);
  };

  return (
    <TextInput
      className={className}
      name={name}
      value={value}
      inputProps={{
        type: "tel",
        inputMode: "tel",
        max: 20,
        onBlur: handleBlur,
        testId: inputTestId,
      }}
      label="Mobile number"
      error={error ?? "Please enter a valid phone number"}
      hasError={isInvalid}
      onChange={handleChange}
      size={inputSize}
    />
  );
};
