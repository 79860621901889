import { type FC } from "react";
import { AutoLayoutStep } from "../../common/autoLayoutStep/autolayoutStep";
import { PhoneOtpStep } from "../../common/containers/phone/phone-otp";
import { OnboardingPhoneOtp } from "../../common/phone/phoneChat/onboardingPhoneOtp";

export const phoneOtpStepTestIds = {
  root: "phone-otp-step",
};

export const ChatPhoneOtpStep: FC = () => (
  <PhoneOtpStep>
    {(props) => (
      <AutoLayoutStep name="phone-otp">
        <OnboardingPhoneOtp {...props} testId={phoneOtpStepTestIds.root} />
      </AutoLayoutStep>
    )}
  </PhoneOtpStep>
);
