import { observer } from "mobx-react-lite";
import {
  type ComponentType,
  type ReactNode,
  useCallback,
  useState,
} from "react";
import { useServices } from "../../common/services/servicesContext";
import { type Profile } from "../../services/profile/models";
import { type User } from "../../services/user/models";
import { getStepperContext } from "./context";

export interface StepperProps<T extends string> {
  initialStep: T;
  getStep: (user: User, profile: Profile) => T;
  steps: Record<T, ComponentType>;
  children: (stepContent: ComponentType<any>, step: T) => ReactNode;
}

export const Stepper = observer(function <T extends string>({
  initialStep,
  getStep,
  steps,
  children,
}: StepperProps<T>) {
  const { userState, profileState } = useServices();
  const [step, setStep] = useState<T>(initialStep);
  const Context = getStepperContext<T>();

  const navigateNext = useCallback(() => {
    setStep(getStep(userState.user, profileState.profile));
  }, [getStep, userState.user, profileState.profile]);

  return (
    <Context.Provider
      value={{ current: step, navigateTo: setStep, navigateNext }}
    >
      {children(steps[step], step)}
    </Context.Provider>
  );
});
