import type { IConfig } from "../services/app/appStore";

const getConfig = async (): Promise<IConfig | null> => {
  try {
    const result = await fetch(`${window.location.origin}/config.json`);
    return await result.json();
  } catch (e) {
    console.error("Failed to load configuration file", e);
    return null;
  }
};

export default getConfig;
