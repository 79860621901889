import { Button, Text } from "@anna-money/anna-web-ui";
import { type FC, useRef, useState } from "react";
import { PhoneField } from "../../../components/phone-field";
import { type PhoneBasicContent, type PhoneProps } from "../interfaces";
import { OnboardingPhoneBasic } from "../onboardingPhoneBasic";
import { phoneTestIds } from "../phoneTestIds";
import styles from "./onboardingPhoneClassic.module.scss";

export const OnboardingPhoneClassic: FC<PhoneProps> = ({
  state,
  onSubmit,
  testId,
}) => {
  const [value, setValue] = useState("");
  const isValidRef = useRef(Boolean(value));

  const handleSubmit = (): void => {
    if (isValidRef.current) {
      onSubmit(value);
    }
  };

  const content: PhoneBasicContent = ({ buttonProps }) => (
    <>
      <div className={styles.inputBlock}>
        <PhoneField
          className={styles.input}
          name="phone"
          value={value}
          onChange={setValue}
          onValidityStateChange={(valid) => (isValidRef.current = valid)}
          inputTestId={phoneTestIds.phoneInput}
        />
        <Button
          {...buttonProps}
          className={styles.button}
          size="s"
          testId={phoneTestIds.submitButton}
        />
      </div>
      {state instanceof Error && (
        <Text color="themeTerracotta" className={styles.errorText}>
          {state.message}
        </Text>
      )}
    </>
  );

  return (
    <OnboardingPhoneBasic
      state={state}
      styles={styles}
      onSubmit={handleSubmit}
      content={content}
      testId={testId}
    />
  );
};
