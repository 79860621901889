import { type FC, type ReactNode } from "react";
import styled from "styled-components";

const StepActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin: 0 4px;
  }
`;

interface StepActionsProps {
  className?: string;
  children?: ReactNode;
}

export const StepActions: FC<StepActionsProps> = ({ children, className }) => (
  <StepActionsWrapper className={className}>{children}</StepActionsWrapper>
);
