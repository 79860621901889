import { AnnaError } from "@anna-money/anna-web-lib";
import { observer } from "mobx-react-lite";
import { type FormEventHandler, type ReactElement, useState } from "react";
import { useStepperContext } from "../../../components/stepper/context";
import { type PhoneOtpProps } from "../../phone/interfaces";
import { useServices } from "../../services/servicesContext";

type PhoneOtpStepProps = {
  children: (props: PhoneOtpProps) => ReactElement;
};

type StepType = "phone";

export const PhoneOtpStep = observer(
  <T extends StepType>({ children }: PhoneOtpStepProps) => {
    const { phoneStore, userState } = useServices();
    const { navigateTo, navigateNext } = useStepperContext<T>();

    const [otp, setOTP] = useState("");

    if (!userState.user.phoneNumber) {
      throw new AnnaError("Phone otp can't be rendered without a phoneNumber");
    }

    const handleSubmit: FormEventHandler<
      HTMLFormElement
    > = async (): Promise<void> => {
      const oneTimeCode = otp.trim();
      if (!oneTimeCode) {
        return;
      }

      try {
        await phoneStore.confirmPhoneNumber(oneTimeCode);
        navigateNext();
      } catch (e) {
        console.error(e);
      }
    };

    const handlePrevClick = (): void => {
      navigateTo("phone" as T);
    };

    return children({
      value: otp,
      phoneNumber: userState.user.phoneNumber,
      onChange: setOTP,
      onSubmit: handleSubmit,
      restOtpSeconds: phoneStore.restOtpSeconds,
      onOTPClick: phoneStore.resendOtpCode,
      onPrevClick: handlePrevClick,
      state: phoneStore.confirmPhoneState,
    });
  },
);
