import { type HttpHandler } from "@anna-money/anna-web-lib";
import { Loader, TextItem } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef } from "react";
import { type IApiClient } from "./api/apiClient";
import { type IAuthStore } from "./auth/authStore";
import { BootstrappedApp } from "./bootstrappedApp";
import { useAttribution } from "./classicSurvey/use-attribution";
import { sendHashedEmail } from "./common/helpers";
import { Services } from "./common/services/services";
import { ServicesContext } from "./common/services/servicesContext";
import { type SentryHost } from "./logging/sentryHost";
import {
  type IAnalyticsClient,
  createLocationParams,
} from "./services/analyticsClient";
import { type IAppStore } from "./services/app/appStore";
import { ProfileApiClient } from "./services/profile/api";
import { ProfileStore } from "./services/profile/profileStore";
import { type UserApiClient } from "./services/user/api";
import { type IUserStore } from "./services/user/userStore";

type AuthenticatedProps = {
  apiClient: IApiClient;
  userApiClient: UserApiClient;
  analyticsClient: IAnalyticsClient;
  appStore: IAppStore;
  authStore: IAuthStore;
  authHandler: HttpHandler;
  userStore: IUserStore;
  sentryHost: SentryHost;
};

export const Authenticated = observer<AuthenticatedProps>(
  ({
    apiClient,
    userApiClient,
    analyticsClient,
    appStore,
    authStore,
    authHandler,
    userStore,
    sentryHost,
  }) => {
    const profileApiClient = useRef(new ProfileApiClient(apiClient)).current;
    const profileStore = useRef(
      new ProfileStore(appStore, profileApiClient),
    ).current;
    const services = useMemo(
      () =>
        userStore.isReady(userStore.state) &&
        profileStore.isReady(profileStore.state)
          ? new Services(
              apiClient,
              appStore,
              userStore.state,
              userApiClient,
              profileStore.state,
              analyticsClient,
              authStore,
              authHandler,
              sentryHost,
            )
          : null,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        analyticsClient,
        profileStore,
        profileStore.state,
        sentryHost,
        userApiClient,
        userStore,
        userStore.state,
        apiClient,
        appStore,
        authStore,
        authHandler,
      ],
    );

    useAttribution(profileStore, apiClient);

    useEffect(() => {
      if (userStore.isReady(userStore.state)) {
        sendHashedEmail(userStore.state);
      }
    }, [userStore.state, userStore]);

    useEffect(() => {
      if (!userStore.isReady(userStore.state)) {
        return;
      }
      const params = createLocationParams(authStore.state);

      void analyticsClient.postEvent(
        "webonboarding.page_view",
        params,
        userStore.state.alias,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.state, userStore.state]);

    useEffect(() => {
      void userStore.load();
      void profileStore.load();
    }, [userStore, profileStore]);

    if (!services) {
      return <Loader />;
    }

    if (userStore.state === "error" || profileStore.state === "error") {
      return <TextItem>Something went wrong</TextItem>;
    }

    return (
      <ServicesContext.Provider value={services}>
        <BootstrappedApp />
      </ServicesContext.Provider>
    );
  },
);
