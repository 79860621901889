import { makeAutoObservable } from "mobx";
import { type LoadableState } from "../../utils/state";
import { type UserApiClient } from "./api";
import { type IUserState, UserState } from "./userState";

export type State = LoadableState<IUserState>;

export interface IUserStore {
  isReady: (state: State) => state is IUserState;
  state: State;
  load: () => Promise<void>;
}

export class UserStore implements IUserStore {
  private readonly _userApiClient: UserApiClient;
  private _state: State = "loading";

  constructor(userApiClient: UserApiClient) {
    makeAutoObservable(this);
    this._userApiClient = userApiClient;
  }

  isReady = (state: State): state is UserState => {
    return state instanceof UserState;
  };

  get state(): State {
    return this._state;
  }

  private set state(state: State) {
    this._state = state;
  }

  load = async (): Promise<void> => {
    this.state = "loading";
    try {
      this.state = await UserState.create(this._userApiClient);
    } catch (e) {
      this.state = "error";
    }
  };
}
