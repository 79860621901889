import { useEffect } from "react";
import { useServices } from "./services/servicesContext";

export const useAnalyticsStepView = (step: string): void => {
  const { analyticsClient, userState } = useServices();

  useEffect(() => {
    void analyticsClient.postEvent(
      "webonboarding.step_view",
      {
        step,
      },
      userState.alias,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, userState.alias]);
};
