import {
  AnnaErrorBase,
  HttpClient,
  type HttpResponse,
  type Nullable,
  getHttpError,
} from "@anna-money/anna-web-lib";

export abstract class AnalyticsErrorBase extends AnnaErrorBase {}

export class AnalyticsClientError extends AnalyticsErrorBase {
  constructor(message: string, cause: unknown) {
    super(message, { cause });
  }
}

export const createLocationParams = (
  authState: string,
): Record<string, string> => {
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries(),
  );
  params.host = window.location.host;
  params.raw_url = window.location.href;
  params.auth_state = authState;
  params.referrer = document.referrer;
  return params;
};

export class AnalyticsServerError extends AnalyticsErrorBase {
  constructor(message: string, cause: unknown) {
    super(message, { cause });
  }
}

export interface IAnalyticsClient {
  postEvent: (
    eventType: string,
    payload: Record<string, unknown>,
    alias?: string,
  ) => Promise<void>;
}

export class AnalyticsClient implements IAnalyticsClient {
  private readonly _client: Nullable<HttpClient>;

  constructor(apiUrl: Nullable<string>) {
    if (!apiUrl) {
      // To ensure that we know about it in production
      console.warn("Analytics client is not configured");
    } else {
      this._client = new HttpClient(apiUrl);
    }
  }

  async postEvent(
    eventType: string,
    payload: Record<string, unknown>,
    alias?: string,
  ): Promise<void> {
    if (!this._client) {
      // Disabling it for local development
      return;
    }
    const response = await AnalyticsClient._postEvent(
      this._client,
      eventType,
      payload,
      alias,
    );

    if (!response.isOk) {
      throw new AnalyticsServerError(
        "Failed to send analytics",
        getHttpError(response.diagnostics),
      );
    }
  }

  private static async _postEvent(
    client: HttpClient,
    eventType: string,
    payload: Record<string, unknown>,
    alias: string | undefined,
  ): Promise<HttpResponse<any>> {
    try {
      const request = await client.request({
        method: "post",
        url: "/api/webtics/i/event",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: JSON.stringify({
          event_type: eventType,
          payload,
          alias,
        }),
      });
      return await request.send();
    } catch (e) {
      throw new AnalyticsClientError("Failed to send analytics", e);
    }
  }
}
