import { Forms, TextItem } from "@anna-money/anna-web-ui";
import styled from "styled-components";

export const Root = styled.div`
  padding: 24px 24px 16px;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 16px -16px rgba(0, 0, 0, 0.05);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AnnaLogo = styled.div`
  width: 93px;
  height: 34px;
  background: url("icons/Anna_Logo_RGB.png");
  background-size: contain;
`;

export const CardTypeText = styled(TextItem).attrs({
  color: "secondary100",
})`
  font-size: 14px !important;
  color: #4e4e4e;
`;

export const MCLogo = styled.div`
  background: url("icons/Card-illustration.svg");
  width: 88px;
  height: 53px;
  background-size: contain;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:first-child {
    margin-bottom: 8px;
  }
`;

export const Input = styled(Forms.TextInput)`
  width: 100%;
  margin-bottom: 8px;

  label {
    font-size: 14px !important;
    color: #757575;
  }

  input {
    padding: 12px 16px !important;
    height: 48px !important;
    font-size: 16px !important;
  }

  input::placeholder {
    color: #757575;
  }

  input:focus {
    background: #ffffff;
    border-color: #0f8566;
    caret-color: #0f8566;
  }
`;

export const InputCounter = styled(TextItem).attrs({ size: "small" })`
  color: #3d3d3d !important;
`;
