import { Subtitle } from "@anna-money/anna-web-ui";
import debounce from "debounce-promise";
import { type ComponentProps, type FC } from "react";
import AsyncSelectComponent from "react-select/async";
import styled from "styled-components";
import { type IApiClient } from "../../../api/apiClient";
import { serializer } from "../../../common/serializer";
import { useServices } from "../../../common/services/servicesContext";
import { AddressSuggestion } from "../../../domain/address";

type AsyncSelectProps = ComponentProps<AsyncSelectComponent>;

const customStyles: AsyncSelectProps["styles"] = {
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
  dropdownIndicator: (provided) => ({ ...provided, display: "none" }),
  input: (provided) => ({ ...provided, cursor: "text", fontSize: 11 }),
  placeholder: (provided) => ({ ...provided, fontSize: 11, color: "#c3c3c3" }),
  singleValue: (provided) => ({ ...provided, fontSize: 11 }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 12,
    paddingRight: 12,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    animation: "none",
    borderColor: state.isFocused ? "#12ad84" : "#e5e5e5",
    ":hover": {
      borderColor: state.isFocused ? "#12ad84" : "#e5e5e5",
    },
  }),
  menu: (provided) => ({ ...provided, fontSize: 11 }),
};

const AsyncSelect = styled(AsyncSelectComponent).attrs({
  isClearable: true,
  cacheOptions: false,
  getOptionLabel: (o: any) => (o as AddressSuggestion).addressText,
  styles: customStyles,
  theme: {
    borderRadius: 4,
    spacing: { controlHeight: 32, baseUnit: 2, menuGutter: 2 },
  } as any,
  placeholder: "Start typing your address",
  "data-test-id": "address-select",
})`
  width: 268px;
`;

interface AddressSelectProps {
  onSelect: (s: AddressSuggestion) => void;
}

export const AddressSelect: FC<AddressSelectProps> = ({ onSelect }) => {
  const { apiClient } = useServices();

  return (
    <>
      <Subtitle level="3">Search for your address with autocomplete</Subtitle>
      <AsyncSelect
        onChange={onSelect as (o: unknown) => void}
        loadOptions={debounce(
          async (value: string) =>
            await getAddressSuggestions(value, apiClient),
          350,
        )}
      />
    </>
  );
};

async function getAddressSuggestions(
  search: string,
  apiClient: IApiClient,
): Promise<AddressSuggestion[]> {
  const result = await apiClient.get(
    `api/web-onboarding/references/addresses/autocomplete?q=${encodeURIComponent(
      search,
    )}`,
  );

  return (result as any)._data.map((a: unknown) =>
    serializer.deserialize(a, AddressSuggestion),
  ) as AddressSuggestion[];
}
