import classNames from "classnames";
import { type CountryCode } from "libphonenumber-js";
import React, {
  type ChangeEventHandler,
  type FC,
  type FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { TextInput } from "../text-input";
import { PhoneCountryList } from "./components/PhoneCountryList/PhoneCountryList";
import {
  COUNTRY_DATA_ARRAY,
  COUNTRY_DATA_MAP,
  parsePhoneNumber,
} from "./helpers";
import styles from "./PhoneInput.module.scss";

const defaultMask = "9999 99999";

type Props = {
  className?: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  selectedCountry: CountryCode;
  onCountryChange: (value: CountryCode) => void;
  onBlur?: React.EventHandler<any>;
  maskPlaceholder: string;
  testId?: string;
};

const testIds = {
  dropdown: "dropdown",
};

export const PhoneInput: FC<Props> = ({
  className,
  value,
  error,
  onChange,
  selectedCountry,
  onCountryChange,
  maskPlaceholder,
  testId,
}) => {
  const selected = COUNTRY_DATA_MAP[selectedCountry];
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputElement = inputRef.current;
    if (containerRef.current) {
      setWidth(containerRef.current.getBoundingClientRect().width);
    }
    const cb = (e: ClipboardEvent): void => {
      if (e.clipboardData?.getData("text")) {
        e.preventDefault();
        onChange(parsePhoneNumber(e.clipboardData?.getData("text")));
      }
    };
    inputElement?.addEventListener("paste", cb);

    return () => inputElement?.removeEventListener("paste", cb);
  }, [onChange]);
  const mask = selected?.mask ?? defaultMask;
  const effectiveMask = value.startsWith("0") ? `9${mask}` : mask;
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!/\d/.test(e.target.value)) {
      // Without animationFrame setSelectionRange doesn't work in Chrome
      window.requestAnimationFrame(() => {
        e.target.setSelectionRange(0, 0);
      });
    }
  };

  return (
    <div
      className={classNames(styles.root, className)}
      ref={containerRef}
      data-test-id={testId}
    >
      <PhoneCountryList
        className={styles.countries}
        selected={selected}
        countries={COUNTRY_DATA_ARRAY}
        onSelect={onCountryChange}
        width={width}
        testId={testIds.dropdown}
      />
      <InputMask
        mask={effectiveMask}
        maskPlaceholder={maskPlaceholder}
        alwaysShowMask={true}
        onChange={handleChange}
        value={value}
      >
        <TextInput
          name="phone-input"
          inputProps={{
            type: "tel",
            inputMode: "tel",
            inputRef,
            onFocus: handleFocus,
            className: styles.input,
          }}
          newDesign
          error={error}
          hasError={!!error}
          size="l"
        />
      </InputMask>
    </div>
  );
};
