import {
  AnnaErrorBase,
  ApiError,
  type HttpRequestDiagnostics,
  HttpRequestError,
  NetworkError,
  type Nullable,
  UnexpectedValueError,
  ValidationApiError,
  unwrapError,
} from "@anna-money/anna-web-lib";
import {
  type ErrorEvent,
  captureConsoleIntegration,
  init,
  setUser,
} from "@sentry/browser";
import { reaction } from "mobx";
import { type IAppStore } from "../services/app/appStore";
import { type IUserStore } from "../services/user/userStore";

let preventSending = false;
window.addEventListener("beforeunload", () => {
  preventSending = true;
  // In case if we didn't actually leave
  setTimeout(() => {
    preventSending = false;
  }, 100);
});

window.addEventListener("pagehide", () => {
  preventSending = true;
});

window.addEventListener("pageshow", () => {
  preventSending = false;
});

export class SentryHost {
  constructor(
    appStore: IAppStore,
    private readonly _userStore: IUserStore,
  ) {
    this._initialise(appStore.config.sentryDSN, appStore.config.appVersion);
  }

  private _initialise(sentryDSN: string, appVersion: string): void {
    init({
      allowUrls: [/https?:\/\/([^.]+\.)*anna.money/i],
      dsn: sentryDSN,
      release: appVersion,
      integrations: [
        captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      beforeSend: (event, hint) => {
        if (preventSending || shouldIgnoreEvent(event)) {
          return null;
        }

        const error = hint?.originalException;
        if (!(error instanceof Error)) {
          return event;
        }

        if (unwrapError(error, NetworkError)) {
          return null;
        }

        cleanupStackTrace(event);

        event.extra = event.extra || {};

        // We expect Sentry to handle cause's stack trace but still logging it here
        // in case the cause had some custom properties defined
        event.extra["Error cause"] = (error as any).cause;

        const annaError = unwrapError(error, AnnaErrorBase);
        if (annaError) {
          event.extra["Error extra"] = annaError.extra;
        }

        const httpRequestError = unwrapError(error, HttpRequestError);
        if (httpRequestError) {
          event.tags = event.tags || {};
          event.tags["request.method"] =
            httpRequestError.diagnostics.request.method.toLowerCase();
          event.tags["request.baseUrl"] = httpRequestError.diagnostics.baseUrl;
          event.tags["request.url"] = httpRequestError.diagnostics.request.url;
          event.tags["request.httpCode"] =
            httpRequestError.diagnostics.httpCode;
          event.tags["request.apiCode"] =
            httpRequestError instanceof ApiError
              ? httpRequestError.code
              : undefined;

          event.extra["Request diagnostics"] = redactDiagnostics(
            httpRequestError.diagnostics,
          );
          if (httpRequestError instanceof ValidationApiError) {
            event.extra["API validation errors"] = httpRequestError.getErrors();
          }
        }

        return event;
      },
    });
    reaction(
      () => this._userStore.state,
      (state) => {
        if (this._userStore.isReady(state)) {
          this._setUser(state.alias);
        }
      },
      { fireImmediately: true },
    );
  }

  private _setUser(alias: string): void {
    setUser({ id: alias });
  }
}

const errorConstructorRegex = /^(?:[A-Z]\w*)?Error/;

/**
 * Removing Error classes constructors from stack trace
 */
function cleanupStackTrace(event: ErrorEvent): void {
  const error = event.exception?.values?.[0];
  if (!error) {
    return;
  }
  const frames = error.stacktrace?.frames;
  if (!frames) {
    return;
  }
  for (const frame of frames) {
    if (frame.function && errorConstructorRegex.test(frame.function)) {
      frame.in_app = false;
    }
  }
}

const errorFilters = [
  { stack: "chrome-extension://" },
  { message: "chrome-extension://" },
] as const;

function shouldIgnoreEvent(event: ErrorEvent): boolean {
  const error = event.exception?.values?.[0];
  for (const filter of errorFilters) {
    if (
      "message" in filter &&
      (matchesFilter(event.message, filter.message) ||
        matchesFilter(error?.value, filter.message))
    ) {
      return true;
    }
    if (
      "stack" in filter &&
      error?.stacktrace?.frames?.some((x) =>
        matchesFilter(x.filename, filter.stack),
      )
    ) {
      return true;
    }
  }
  return false;
}

function matchesFilter(
  value: Nullable<string>,
  filter: string | RegExp,
): boolean {
  if (!value) {
    return false;
  }
  if (typeof filter === "string") {
    return value.toLowerCase().includes(filter.toLowerCase());
  }
  if (filter instanceof RegExp) {
    return filter.test(value);
  }
  throw new UnexpectedValueError("filter", filter);
}

function redactDiagnostics(
  diagnostics: HttpRequestDiagnostics,
): HttpRequestDiagnostics {
  const headers = diagnostics.request.headers;
  const authorizationHeader = headers
    ? Object.keys(headers).find((x) => x.toLowerCase() === "authorization")
    : null;
  if (!authorizationHeader) {
    return diagnostics;
  }
  const redactedHeaders = { ...headers };
  redactedHeaders[authorizationHeader] = "<redacted>";
  return {
    ...diagnostics,
    request: {
      ...diagnostics.request,
      headers: redactedHeaders,
    },
  };
}
