import { action, computed, makeObservable, observable } from "mobx";
import { type AgreementType } from "./interfaces";
import { Agreements } from "./onboardingPolicyModels";
import { type OnboardingPolicyStoreApi } from "./onboardingPolicyStoreApi";

type SubmitState = "idle" | "error" | "loading";

export class OnboardingPolicyStore<
  T extends OnboardingPolicyStoreApi = OnboardingPolicyStoreApi,
> {
  // use plain object here, because mobx observable doesn't work with Serializable model
  protected _agreements: Agreements = {
    termsAndConditions: false,
    customerAgreement: false,
    privacyPolicy: false,
  };
  protected _submitState: SubmitState = "idle";

  get submitState(): SubmitState {
    return this._submitState;
  }

  protected set submitState(state: SubmitState) {
    this._submitState = state;
  }

  constructor(protected readonly _api: T) {
    makeObservable(this, {
      _agreements: observable.deep,
      _submitState: observable,
      submitState: computed,
      toggleAgreement: action,
      agreements: computed,
      isAccepted: computed,
      submit: action,
    } as any);
  }

  toggleAgreement = (agreementType: AgreementType): void => {
    this._agreements[agreementType] = !this._agreements[agreementType];
  };

  get agreements(): Agreements {
    return this._agreements;
  }

  get isAccepted(): boolean {
    const { termsAndConditions, privacyPolicy, customerAgreement } =
      this._agreements;
    return termsAndConditions && privacyPolicy && customerAgreement;
  }

  async submit(): Promise<void> {
    this.submitState = "loading";
    try {
      const { customerAgreement, privacyPolicy, termsAndConditions } =
        this._agreements;
      await this._api.setAgreements(
        new Agreements(customerAgreement, privacyPolicy, termsAndConditions),
      );
      await this._api.acceptAgreement();
      this.submitState = "idle";
    } catch (e) {
      this.submitState = "error";
      throw e;
    }
  }
}
