import styled from "styled-components";

// propagates keyboard events to form elements
export const HiddenSubmit = styled.input.attrs({
  type: "submit",
  "aria-hidden": true,
})`
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0;
  border: 0;
  padding: 0;
  visibility: hidden;
`;
