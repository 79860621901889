import { SHA256, enc } from "crypto-js";
import TagManager from "react-gtm-module";
import { type User } from "../services/user/models";
import { type IUserState } from "../services/user/userState";

export function replaceSearchParams(
  url: URL,
  searchParams: URLSearchParams,
): void {
  for (const name of Array.from(url.searchParams.keys())) {
    url.searchParams.delete(name);
  }

  for (const [name, value] of Array.from(searchParams.entries())) {
    url.searchParams.append(name, value);
  }
}

export function hashEmail(user: User): string {
  return SHA256(user.email).toString(enc.Hex);
}

export const sendHashedEmail = (userState: IUserState): void => {
  const result = hashEmail(userState.user);
  const tagManagerArgs = {
    gtmId: "GTM-PTS83DD",
    dataLayer: {
      email: result,
    },
  };
  TagManager.initialize(tagManagerArgs);
  TagManager.dataLayer(tagManagerArgs);
};
