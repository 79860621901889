import { Button } from "@anna-money/anna-web-ui";
import type { FC } from "react";

export const AnnaDeepLink: FC<{ link: string }> = (props) => (
  <Button
    type="primary"
    text="Open the app"
    onClick={() => window.open(props.link)}
  />
);
