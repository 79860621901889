import { Button } from "@anna-money/anna-web-ui";
import { type CountryCode } from "libphonenumber-js";
import { type FC, useState } from "react";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { COUNTRY_DATA_MAP } from "../../../components/phoneInput/helpers";
import { type PhoneBasicContent, type PhoneProps } from "../interfaces";
import { OnboardingPhoneBasic } from "../onboardingPhoneBasic";
import { phoneTestIds } from "../phoneTestIds";
import styles from "./onboardingPhone.module.scss";

const maskPlaceholder = "_";

export const OnboardingPhone: FC<PhoneProps> = ({
  state,
  onSubmit,
  testId,
}) => {
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState<CountryCode>("GB");
  const selected = COUNTRY_DATA_MAP[countryCode];
  // Handle the case when a full phone number with +44 at the beginning has been passed,
  // but we don't want to have 44 at the beginning of the input
  const effectiveValue = value.replace(`+${selected.callingCode}`, "");
  const [isValid, setIsValid] = useState(true);
  const getPurifiedValue = (maskedValue: string): string =>
    maskedValue.replace(maskPlaceholder, "").replace(/(^0|\s)/g, "");
  const checkIsValid = (maskedValue: string): boolean =>
    COUNTRY_DATA_MAP[countryCode]?.mask?.replace(/\s/g, "").length ===
    getPurifiedValue(maskedValue).length;

  const handleChange = (newValue: string): void => {
    if (!isValid) {
      setIsValid(checkIsValid(newValue));
    }
    setValue(newValue);
  };

  const handleSubmit = (): void => {
    const effectiveValid = checkIsValid(effectiveValue);

    setIsValid(effectiveValid);

    if (effectiveValid) {
      onSubmit(`+${selected.callingCode}${getPurifiedValue(effectiveValue)}`);
    }
  };

  const handleCountryChange = (code: CountryCode): void => {
    setCountryCode(code);
    setIsValid(true);
    setValue("");
  };

  const getErrorMessage = (): string | undefined => {
    if (!isValid) {
      return "Please enter a valid phone number";
    }
    return state instanceof Error ? state.message : undefined;
  };

  const content: PhoneBasicContent = ({ buttonProps }) => (
    <>
      <PhoneInput
        className={styles.input}
        value={effectiveValue}
        error={getErrorMessage()}
        onChange={handleChange}
        selectedCountry={countryCode}
        onCountryChange={handleCountryChange}
        maskPlaceholder={maskPlaceholder}
        testId={phoneTestIds.phoneInput}
      />
      <Button
        {...buttonProps}
        className={styles.button}
        size="l"
        testId={phoneTestIds.submitButton}
      />
    </>
  );

  return (
    <OnboardingPhoneBasic
      state={state}
      content={content}
      styles={styles}
      onSubmit={handleSubmit}
      testId={testId}
    />
  );
};
