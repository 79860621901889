import { type IApiClient } from "../../api/apiClient";
import { serializer } from "../../common/serializer";
import { Company, Profile } from "./models";

export interface IProfileApiClient {
  apiGetProfile: () => Promise<Profile>;
  apiSetProfile: (profile: Profile) => Promise<Profile>;
  apiActivateProfile: () => Promise<void>;
  apiGetCompaniesByOfficer: (
    fullName: string,
    dob?: string,
    postcode?: string,
  ) => Promise<Company[]>;
}

export class ProfileApiClient implements IProfileApiClient {
  constructor(private readonly _apiClient: IApiClient) {}

  apiGetProfile = async (): Promise<Profile> => {
    const result = await this._apiClient.get("api/web-onboarding/profile");
    return result.getData(Profile);
  };

  apiSetProfile = async (profile: Profile): Promise<Profile> => {
    const result = await this._apiClient.post(
      "api/web-onboarding/profile",
      serializer.serialize(profile),
    );
    return result.getData(Profile);
  };

  apiActivateProfile = async (): Promise<void> => {
    await this._apiClient.post("api/web-onboarding/profile/activate", {});
  };

  apiGetCompaniesByOfficer = async (
    fullName: string,
    dob?: string,
    postcode?: string,
  ): Promise<Company[]> => {
    let search = `?full_name=${encodeURIComponent(fullName)}`;

    if (dob) {
      search += `&date_of_birth=${encodeURIComponent(dob)}`;
    }

    if (postcode) {
      search += `&postcode=${encodeURIComponent(postcode)}`;
    }

    const result = await this._apiClient.get(
      `api/web-onboarding/references/companies-by-officer${search}`,
    );
    return (result as any)._data.map((c: unknown) =>
      serializer.deserialize(c, Company),
    );
  };
}
