import { Button, Text } from "@anna-money/anna-web-ui";
import debounce from "lodash/debounce";
import { observer } from "mobx-react-lite";
import {
  type ChangeEvent,
  type FormEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { type IApiClient } from "../../api/apiClient";
import { serializer } from "../../common/serializer";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { TextInput } from "../../components/text-input";
import { Company, CompanyStatus } from "../../services/profile/models";
import { ProfileValidationError } from "../../services/profile/profileState";
import { CompanyList } from "./components/company-list";

export const CompanyNumberSearchStep = observer(() => {
  const { profileState, apiClient } = useServices();
  const { profile, setCompany, isSettingCompany } = profileState;
  const [companyError, setCompanyError] = useState("");
  const { navigateTo } = useStepperContext();

  const [query, setQuery] = useState("");
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
    profile.company?.companyNumber ?? undefined,
  );

  const findCompanies = useMemo(
    () =>
      debounce(async (search: string) => {
        const list = await queryCompanies(search, apiClient);
        if (!list) {
          return;
        }

        setCompanies(list);
      }, 350),
    [apiClient],
  );

  useEffect(() => {
    setSelectedCompany(undefined);

    if (!query) {
      setCompanies([]);

      return;
    }

    void findCompanies(query);
  }, [query, findCompanies]);

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const isInvalidSelection = useMemo(() => {
    if (!selectedCompany) {
      return false;
    }

    return companies.some(
      ({ companyNumber, status }) =>
        companyNumber === selectedCompany && status !== CompanyStatus.active,
    );
  }, [selectedCompany, companies]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();

    if (!selectedCompany || isInvalidSelection) {
      return;
    }

    try {
      await setCompany(selectedCompany);
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setCompanyError(e.nestedErrors[0]?.title ?? "");
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("kind");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit}>
      <StepWrapper>
        <StepTitle>Help us find your registered company</StepTitle>
        <StepDescription>
          Please provide either your Company Name or your Company Number.
        </StepDescription>

        <div>
          <TextInput
            name="company-name"
            label="Company Name or Registration Number"
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
          />

          {companies.length > 0 && (
            <>
              <Gap />

              <Text>
                We found these companies matching your company name or company
                number at Companies House:
              </Text>

              <Gap />

              <CompanyList
                list={companies}
                value={selectedCompany}
                onChange={setSelectedCompany}
              />
            </>
          )}
        </div>

        <StepActions>
          <SecondaryActionButton
            text="Back"
            disabled={isSettingCompany}
            onClick={handlePrevClick}
          />
          <Button
            type="primary"
            text={isSettingCompany ? "Loading..." : "Next"}
            disabled={isSettingCompany || isInvalidSelection}
            onClick={handleNextClick}
          />
        </StepActions>

        {companyError && <Text color="themeTerracotta">{companyError}</Text>}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});

async function queryCompanies(
  search: string,
  apiClient: IApiClient,
): Promise<Company[]> {
  const result = await apiClient.get(
    `api/web-onboarding/references/companies?q=${encodeURIComponent(search)}`,
  );

  return (result as any)._data.map((c: unknown) =>
    serializer.deserialize(c, Company),
  );
}
