import { type FC } from "react";
import { PolicyStep } from "../../common/containers/policy/policyStep";
import { OnboardingPolicyClassic } from "../../common/policy/onboardingPolicyClassic";

export const ClassicPolicyStep: FC = () => {
  return (
    <PolicyStep>
      {(props) => <OnboardingPolicyClassic {...props} testId="policy-step" />}
    </PolicyStep>
  );
};
