import { type Context, createContext, useContext } from "react";

interface StepperContextValue<T extends string> {
  current: T;
  navigateTo: (key: T) => void;
  navigateNext: () => void;
}

const StepperContext = createContext<StepperContextValue<any> | null>(null);

export const getStepperContext = <
  T extends string,
>(): Context<StepperContextValue<T> | null> => StepperContext;

export const useStepperContext = <
  T extends string,
>(): StepperContextValue<T> => {
  const context = getStepperContext<T>();
  const value = useContext(context);

  if (!value) {
    throw new Error("Couldn't find `StepperContext`");
  }

  return value;
};
