import { makeAutoObservable } from "mobx";
import { type LoadableState } from "../../utils/state";
import { type IAppStore } from "../app/appStore";
import { type ProfileApiClient } from "./api";
import { type IProfileState, ProfileState } from "./profileState";

export type State = LoadableState<IProfileState>;

export interface IProfileStore {
  state: State;
  isReady: (state: State) => state is IProfileState;
  load: () => Promise<void>;
}

export class ProfileStore implements IProfileStore {
  private readonly _profileApiClient: ProfileApiClient;
  private readonly _appStore: IAppStore;
  private _state: State = "loading";

  constructor(appStore: IAppStore, profileApiClient: ProfileApiClient) {
    makeAutoObservable(this);
    this._profileApiClient = profileApiClient;
    this._appStore = appStore;
  }

  get state(): State {
    return this._state;
  }

  private set state(state: State) {
    this._state = state;
  }

  isReady = (state: State): state is IProfileState =>
    state instanceof ProfileState;

  load = async (): Promise<void> => {
    this.state = "loading";
    try {
      const profile = await this._profileApiClient.apiGetProfile();
      this.state = new ProfileState(
        this._appStore,
        this._profileApiClient,
        profile,
      );
    } catch (e) {
      this.state = "error";
    }
  };
}
