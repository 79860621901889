import { type Profile } from "../services/profile/models";
import { type User } from "../services/user/models";
import { type ChatSurveyStep } from "./chatSurveyStep";

export const getChatSurveyStep = (
  user: User,
  profile: Profile,
): ChatSurveyStep => {
  if (!user.isPolicyAccepted) {
    return "policy";
  }

  if (!user?.alias || !profile?.alias || !user.isPhoneNumberVerified) {
    return "phone";
  }

  return "chat";
};
