import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class Agreements {
  constructor(
    @JsonProperty({ name: "CUSTOMER_AGREEMENT" })
    public customerAgreement: boolean,
    @JsonProperty({ name: "PRIVACY_POLICY" }) public privacyPolicy: boolean,
    @JsonProperty({ name: "TERMS_AND_CONDITIONS" })
    public termsAndConditions: boolean,
  ) {}
}

@Serializable()
export class AgreementState {
  constructor(
    @JsonProperty({ required: false, type: String })
    readonly agreementSignedAt?: string,
  ) {}
}
