import { type SeeAllVariantsAction } from "@anna-money/anna-web-chat";
import { Interactor, Radio, TextItem } from "@anna-money/anna-web-ui";
import { type FC, useState } from "react";
import { DetailsPanel } from "../../components/DetailsPanel/detailsPanel";
import { type OnboardingChatStore } from "../../onboardingChatStore";
import styles from "./seeAllVariantsPanel.module.scss";

type Props = {
  action: SeeAllVariantsAction;
  chatStore: OnboardingChatStore;
};

export const SeeAllVariantsPanel: FC<Props> = ({ action, chatStore }) => {
  const [selectedVariant, setSelectedVariant] = useState<number | null>(null);

  const onSubmit = async (): Promise<void> => {
    if (selectedVariant !== null) {
      chatStore.chatInteractor?.handleActionButton(
        action.list[selectedVariant].payload,
      );
      chatStore.closeDetailsPanel();
    }
  };

  return (
    <DetailsPanel
      name="SeeAllVariantsPanel"
      title={action.screenTitle}
      submit={
        selectedVariant !== null
          ? {
              buttonText: "It's this one",
              onSubmit,
            }
          : undefined
      }
      onClose={chatStore.closeDetailsPanel}
    >
      <div className={styles.content}>
        {action.list.map((item, index) => (
          <Interactor
            className={styles.variant}
            key={item.titles?.[0]}
            onClick={() => {
              setSelectedVariant(index);
            }}
          >
            <Radio
              value={item.titles[0]}
              name={item.titles[0]}
              size="l"
              checked={index === selectedVariant}
            />
            <label htmlFor={item.titles[0]} className={styles.variantText}>
              <TextItem size="small" weight="bold" tag="div">
                {item.titles[0]}
              </TextItem>
              {item.titles[1] && (
                <TextItem size="small" tag="div">
                  {item.titles[1]}
                </TextItem>
              )}
            </label>
          </Interactor>
        ))}
      </div>
    </DetailsPanel>
  );
};
