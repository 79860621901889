import { Autolayout, AutolayoutPanel } from "@anna-money/anna-web-ui";
import { type FC, type ReactElement } from "react";
import styles from "./autoLayoutStep.module.scss";

type AutoLayoutStepProps = {
  name: string;
  children: ReactElement;
};

export const AutoLayoutStep: FC<AutoLayoutStepProps> = ({ name, children }) => {
  return (
    <Autolayout name="web-onboarding" className={styles.autoLayout}>
      <AutolayoutPanel
        title="Get an ANNA Business Account"
        name={name}
        size="fluid"
      >
        <div className={styles.autoLayoutPanelContent}>{children}</div>
      </AutolayoutPanel>
    </Autolayout>
  );
};
