import { type ApiClient } from "@anna-money/anna-web-lib";
import { extendObservable } from "mobx";
import {
  InitializePhoneNumberChange,
  SavePhoneNumber,
} from "common/phone/onboardingPhoneModels";
import { serializer } from "common/serializer";
import {
  AgreementState,
  Agreements,
} from "../../common/policy/onboardingPolicyModels";
import { User } from "./models";

export class UserApiClient {
  constructor(private readonly _apiClient: ApiClient) {}

  apiGetUser = async (): Promise<User> => {
    const result = await this._apiClient.get("api/web-onboarding/me");
    const user = result.getData(User);
    if (user.phoneNumber) {
      user.phoneNumber = user.phoneNumber.replace("+44", "");
    }
    extendObservable(user, { ...user });
    return user;
  };

  apiGetAgreements = async (): Promise<Agreements> => {
    const result = await this._apiClient.get("api/account/v2/agreements");
    return result.getData(Agreements);
  };

  apiSetAgreements = async (agreements: Agreements): Promise<void> => {
    await this._apiClient.post(
      "api/account/v2/agreements",
      serializer.serialize(agreements),
    );
  };

  apiGetAgreementState = async (): Promise<AgreementState> => {
    const result = await this._apiClient.get("api/web-onboarding/agreement");
    return result.getData(AgreementState);
  };

  apiAcceptAgreement = async (): Promise<void> => {
    await this._apiClient.post("api/web-onboarding/agreement", {});
  };

  apiInitializePhoneNumberChange =
    async (): Promise<InitializePhoneNumberChange> => {
      const result = await this._apiClient.post(
        "api/auth/v2/phone-number/initiate-change",
        {},
      );
      return result.getData(InitializePhoneNumberChange);
    };

  apiSavePhoneNumber = async (
    phone: string,
    correlationId: string,
  ): Promise<SavePhoneNumber> => {
    const result = await this._apiClient.post(
      "api/auth/v2/phone-number/save-number",
      {
        phone_number: phone,
        correlation_id: correlationId,
      },
    );
    return result.getData(SavePhoneNumber);
  };

  apiResendOTP = async (correlationId: string): Promise<SavePhoneNumber> => {
    const result = await this._apiClient.post(
      "api/auth/v2/phone-number/resend-sms",
      {
        correlation_id: correlationId,
      },
    );
    return result.getData(SavePhoneNumber);
  };

  apiConfirmPhoneNumber = async (
    code: string,
    correlationId: string,
  ): Promise<void> => {
    await this._apiClient.post("api/auth/v2/phone-number/confirm-number", {
      code,
      correlation_id: correlationId,
    });
  };
}
