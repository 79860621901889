import { type IConfig } from "../services/app/appStore";

export interface IAuthClient {
  getAccessToken: (
    clientId: string,
    authCode: string,
    redirectUrl: string,
  ) => Promise<string>;
  logout: () => Promise<void>;
}

export class AuthClient implements IAuthClient {
  constructor(private readonly _config: IConfig) {}

  getAccessToken = async (
    clientId: string,
    authCode: string,
    redirectUrl: string,
  ): Promise<string> => {
    const response = await fetch(`${this._config.annaAuthUrl}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        client_id: clientId,
        code: authCode,
        redirect_uri: redirectUrl,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const content = await response.json();
    if (
      typeof content !== "object" ||
      !("access_token" in content) ||
      typeof content.access_token !== "string"
    ) {
      throw new Error("Unexpected response");
    }

    return content.access_token;
  };

  logout = async (): Promise<void> => {
    await fetch(`${this._config.annaAuthUrl}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
}
