import { type AppAction, RequestMeAction } from "@anna-money/anna-web-chat";
import { makeObservable, override } from "mobx";
import { OnboardingChatStore } from "../../../common/chat/onboardingChatStore";
import { type OnboardingChatStoreApi } from "../../../common/chat/onboardingChatStoreApi";
import { type IProfileState } from "../../../services/profile/profileState";

export class ChatStore extends OnboardingChatStore {
  private readonly _profileState: IProfileState;

  constructor(api: OnboardingChatStoreApi, profileState: IProfileState) {
    super(api);
    makeObservable(this, {
      handleAppAction: override,
    });
    this._profileState = profileState;
  }

  override handleAppAction(action: AppAction): void {
    if (action instanceof RequestMeAction) {
      void this._profileState.updateProfile();
    } else {
      super.handleAppAction(action);
    }
  }
}
