import { type Agreements } from "../../../common/policy/onboardingPolicyModels";
import { type OnboardingPolicyStoreApi } from "../../../common/policy/onboardingPolicyStoreApi";
import { type UserApiClient } from "../../../services/user/api";

export class PolicyStoreApi implements OnboardingPolicyStoreApi {
  constructor(private readonly _userApiClient: UserApiClient) {}

  async setAgreements(agreements: Agreements): Promise<void> {
    await this._userApiClient.apiSetAgreements(agreements);
  }

  async acceptAgreement(): Promise<void> {
    await this._userApiClient.apiAcceptAgreement();
  }
}
