import { type CardDetailsAction } from "@anna-money/anna-web-chat";
import { AnnaError } from "@anna-money/anna-web-lib";
import { type FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { CardDetailsForm } from "../../components/CardDetailsForm/cardDetailsForm";
import { DetailsPanel } from "../../components/DetailsPanel/detailsPanel";

type Props = {
  payload: CardDetailsAction["payload"];
  processing?: boolean;
  onSubmit: (newData: { name: string; company: string }) => void;
  onClose: () => void;
};

const CardWrapper = styled.div`
  margin-top: 16px;
`;

export const extractMaxLength = (pattern: string): number => {
  const matchResult = pattern.match(/\{(\d+),(\d+)\}\$/);

  if (!matchResult?.[2]) {
    throw new AnnaError(`Can't extract the length from a pattern ${pattern}`);
  }

  return Number(matchResult[2]);
};

export const CardDetailsPanel: FC<Props> = ({
  payload,
  onSubmit,
  onClose,
  processing,
}) => {
  const [name, setName] = useState("payload.name");
  const [company, setCompany] = useState("payload.company");
  const pattern = new RegExp(payload.pattern);
  const maxLen = useMemo(
    () => extractMaxLength(payload.pattern),
    [payload.pattern],
  );

  useEffect(() => {
    setName(payload.name);
  }, [payload.name, setName]);

  useEffect(() => {
    setCompany(payload.company);
  }, [payload.company, setCompany]);

  const changeIfValid = (f: (x: string) => void) => (x: string) => {
    if (pattern.test(x)) {
      f(x);
    }
  };
  const isChanged = name !== payload.name || company !== payload.company;

  return (
    <DetailsPanel
      name="EditCardDetails"
      title="Edit card details"
      submit={
        isChanged
          ? {
              onSubmit: () => {
                onSubmit({ name, company });
              },
              buttonText: "Save details",
              processing,
            }
          : undefined
      }
      onClose={onClose}
    >
      <CardWrapper>
        <CardDetailsForm
          name={name}
          onNameChange={changeIfValid(setName)}
          company={company}
          onCompanyChange={changeIfValid(setCompany)}
          maxLen={maxLen}
        />
      </CardWrapper>
    </DetailsPanel>
  );
};
