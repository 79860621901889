import { PageTitle, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import styled from "styled-components";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { StepActions } from "../../components/stepper/actions";
import { StepWrapper } from "../../components/stepper/wrapper";
import { sendOnboardingComplete } from "../../utils/events";
import { AnnaDeepLink } from "./components/anna-deep-link";
import { AnnaQR } from "./components/anna-qr";
import { useDeepLink } from "./hooks/use-deep-link";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Actions = styled(StepActions)`
  align-self: center;

  @media only screen and (min-device-width: 813px) {
    display: none;
  }
`;

const Left = styled(StepWrapper)`
  flex: 1 0 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Right = styled.div`
  flex: 0 0 220px;
  margin-top: 62px;
  margin-left: 16px;

  @media only screen and (max-device-width: 812px) {
    display: none;
  }
`;

const Title = styled(PageTitle)`
  margin: 0;
`;

const DesktopOnly = styled.div`
  margin: 0;

  @media only screen and (max-device-width: 812px) {
    display: none;
  }
`;
const MobileOnly = styled.div`
  margin: 0;

  @media only screen and (min-device-width: 813px) {
    display: none;
  }
`;

const AppStoreLink = styled.a.attrs({
  href: "https://app.adjust.com/x3ucc46",
  target: "_blank",
  rel: "noreferrer",
  children: (
    <img
      src="icons/apple-appstore-badge.svg"
      alt="Available on the App Store"
    />
  ),
})`
  display: inline-block;
  width: 135px;
  height: 40px;
`;

const GooglePlayLink = styled.a.attrs({
  href: "https://app.adjust.com/xyi7vej",
  target: "_blank",
  rel: "noreferrer",
  children: (
    <img src="icons/google-play-badge.svg" alt="Get it on Google Play" />
  ),
})`
  display: inline-block;
  width: 135px;
  height: 40px;
  margin-left: 24px;
`;

export const ContinueInApp = observer(() => {
  const { userState } = useServices();
  const deepLink = useDeepLink();

  useEffect(() => {
    sendOnboardingComplete();
  }, []);

  return (
    <Root data-test-id="continue-in-app-step">
      <Left>
        <div>
          <DesktopOnly>
            <Gap $size={4} />
          </DesktopOnly>

          <Title>Step 1</Title>

          <Gap />

          <DesktopOnly>
            <Text>
              Scan this QR code with the camera on your phone to download the
              ANNA Money app. We also sent you a download link by SMS.
            </Text>
          </DesktopOnly>

          <MobileOnly>
            <Text>Download the ANNA Money app here</Text>
            <Gap />

            <AppStoreLink />
            <GooglePlayLink />
          </MobileOnly>

          <Gap $size={2} />

          <Title>Step 2</Title>

          <Gap />

          <DesktopOnly>
            <Text>
              Once you've downloaded the ANNA Money app, pop back to this page
              and scan the QR again to login.
            </Text>
          </DesktopOnly>

          <MobileOnly>
            <Text>
              Once you've downloaded the ANNA Money app, tap the button below to
              can finish your application in the ANNA Money app.
            </Text>
          </MobileOnly>

          <Gap />

          <Text>
            Or simply use this email address to login
            <br />
            <strong>{userState.user.email}</strong>
          </Text>
        </div>

        {deepLink && (
          <Actions>
            <AnnaDeepLink link={deepLink} />
          </Actions>
        )}
      </Left>
      <Right>
        {deepLink && (
          <>
            <AnnaQR magicLink={deepLink} />
          </>
        )}
      </Right>
    </Root>
  );
});
