import {
  AsYouType,
  type CountryCallingCode,
  type CountryCode,
  type PhoneNumber,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
} from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";

export interface CountryData {
  countryCode: CountryCode;
  callingCode: CountryCallingCode;
  mask: string;
  countryName: string;
}

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

const isValid = (data: {
  countryCode: CountryCode;
  exampleNumber: PhoneNumber | undefined;
}): data is { countryCode: CountryCode; exampleNumber: PhoneNumber } => {
  return !!data.exampleNumber;
};

export const COUNTRY_DATA_ARRAY: CountryData[] = getCountries()
  .map((countryCode) => ({
    countryCode,
    exampleNumber: getExampleNumber(countryCode, examples),
  }))
  .filter(isValid)
  .map(({ countryCode, exampleNumber }) => {
    const formatter = new AsYouType(countryCode);
    formatter.input(exampleNumber.number);
    const callingCode = getCountryCallingCode(countryCode);

    return {
      countryCode,
      callingCode,
      mask: formatter
        .getTemplate()
        .substring(callingCode.length + 1) // the '+' and a calling code
        .replace(/x/g, "9")
        .trim(),
      countryName: regionNames.of(countryCode) ?? "",
    };
  })
  .sort((a, b) => a.countryName.localeCompare(b.countryName, "en"));

export type CountryDataMap = Record<CountryCode, CountryData>;

export const COUNTRY_DATA_MAP = COUNTRY_DATA_ARRAY.reduce(
  (result, current) => ({
    ...result,
    [current.countryCode]: current,
  }),
  {},
) as CountryDataMap;

// remove leading zero and white spaces
export const parsePhoneNumber = (rawNumber: string): string => {
  return rawNumber.replace(/^(0|\s)+/, "");
};
