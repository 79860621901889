import {
  type HttpHandler,
  type HttpRequestParamsOverride,
  type HttpResponseOverride,
} from "@anna-money/anna-web-lib";
import { type IAuthStore } from "./authStore";
import { type IAuthenticator } from "./authenticator";

export class AuthHandler implements HttpHandler {
  constructor(
    private readonly _authenticator: IAuthenticator,
    private readonly _authStore: IAuthStore,
  ) {}

  async handleRequest(
    request: HttpRequestParamsOverride,
  ): Promise<HttpRequestParamsOverride> {
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${this._authenticator.accessToken}`,
      },
    };
  }

  async handleResponse(
    _: HttpRequestParamsOverride,
    response: HttpResponseOverride<any>,
  ): Promise<void> {
    if (response.status === 401) {
      this._authStore.logout();

      const logoutUrl = this._authStore.getLogoutUrl();
      window.location.replace(logoutUrl);
    }
  }
}
