import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum ProfileKind {
  company = "COMPANY",
  soleTrader = "SOLE_TRADER",
}

export enum OnboardingMode {
  classic = "CLASSIC",
  chat = "CHAT",
  noBankingToBanking = "NO_BANKING_TO_BANKING",
  classicWithOptionalChat = "CLASSIC_WITH_OPTIONAL_CHAT",
}

export enum OnboardingContinuationMode {
  web = "WEB",
  mobile = "MOBILE",
}

@Serializable()
export class ProfileCompany {
  constructor(
    @JsonProperty({ required: false, type: String })
    readonly companyNumber?: string,
  ) {}
}

@Serializable()
export class ProfileSoleTrader {
  constructor(
    @JsonProperty({ required: false, type: String })
    readonly useOwnName?: boolean,
    @JsonProperty({ required: false, type: String })
    readonly tradingName?: string,
  ) {}
}

@Serializable()
export class ProfileHomeAddress {
  constructor(
    @JsonProperty({ required: false, type: String })
    readonly houseNameOrNumber?: string,
    @JsonProperty({ required: false, type: String }) readonly street?: string,
    @JsonProperty({ required: false, type: String }) readonly locality?: string,
    @JsonProperty({ required: false, type: String }) readonly town?: string,
    @JsonProperty({ required: false, type: String }) readonly county?: string,
    @JsonProperty({ required: false, type: String }) readonly postcode?: string,
  ) {}
}

@Serializable()
export class Profile {
  constructor(
    @JsonProperty() readonly alias: string,
    @JsonProperty({ type: OnboardingMode })
    readonly onboardingMode: OnboardingMode,
    @JsonProperty({ required: false, type: OnboardingContinuationMode })
    public onboardingContinuationMode?: OnboardingContinuationMode,
    @JsonProperty({ type: Boolean, required: false })
    public activated?: boolean,
    @JsonProperty({ required: false, type: ProfileCompany })
    public company?: ProfileCompany,
    @JsonProperty({ required: false, type: String }) public lastName?: string,
    @JsonProperty({ required: false, type: String })
    public dateOfBirth?: string,
    @JsonProperty({ required: false, type: ProfileSoleTrader })
    public soleTrader?: ProfileSoleTrader,
    @JsonProperty({ required: false, type: ProfileHomeAddress })
    public homeAddress?: ProfileHomeAddress,
    @JsonProperty({ required: false, type: String })
    public middleNames?: string,
    @JsonProperty({ required: false, type: String }) public firstName?: string,
    @JsonProperty({ required: false, type: ProfileKind })
    public entityType?: ProfileKind,
  ) {}
}

export enum CompanyStatus {
  active = "ACTIVE",
  dissolved = "DISSOLVED",
  prohibited = "PROHIBITED",
}

@Serializable()
export class Company {
  constructor(
    @JsonProperty() readonly registeredOfficeAddress: ProfileHomeAddress,
    @JsonProperty() readonly companyNumber: string,
    @JsonProperty() readonly title: string,
    @JsonProperty({ type: CompanyStatus }) readonly status: CompanyStatus,
    @JsonProperty({ required: false, type: String })
    readonly govUkStatus?: string,
    @JsonProperty({ required: false, type: String }) readonly subtype?: string,
    @JsonProperty({ required: false, type: String })
    readonly natureOfBusiness?: string,
    @JsonProperty({ required: false, type: String })
    readonly dateIncorporatedOn?: string,
  ) {}
}
