import { observer } from "mobx-react-lite";
import { type FC, useCallback, useEffect } from "react";
import { type Location, useLocation, useNavigate } from "react-router-dom";
import { replaceSearchParams } from "../common/helpers";
import {
  type IAnalyticsClient,
  createLocationParams,
} from "../services/analyticsClient";
import { type IAuthStore } from "./authStore";

const REDIRECT_URL_PARAM_KEY = "redirect";

const getFullLocationPath = (location: Location): string => {
  const result = [location.pathname];
  if (location.search) {
    result.push("?", new URLSearchParams(location.search).toString());
  }
  if (location.hash) {
    result.push(location.hash);
  }
  return result.join("");
};

/**
 * Captures current (site entry) url and redirects to `AuthStartPage`
 * preserving current url if the user is unauthenticated
 */
export const AuthCapturePage = observer<{
  authStore: IAuthStore;
  analyticsClient: IAnalyticsClient;
  redirect: string;
}>(({ authStore, analyticsClient, redirect }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    authStore.initialize();

    if (authStore.state !== "authenticated") {
      void analyticsClient
        .postEvent(
          "webonboarding.page_view",
          createLocationParams(authStore.state),
        )
        .finally(() => {
          navigate({
            pathname: redirect,
            search: new URLSearchParams({
              [REDIRECT_URL_PARAM_KEY]: getFullLocationPath(location),
            }).toString(),
          });
        });
    }
  }, [
    analyticsClient,
    navigate,
    redirect,
    authStore,
    authStore.state,
    location,
  ]);

  return <div>Loading...</div>;
});

const getAuthCallbackUrl = (currentUrl: URL, authFinishPath: string): URL => {
  const redirectUrl = currentUrl.searchParams.get(REDIRECT_URL_PARAM_KEY);
  if (!redirectUrl) {
    throw new Error("No redirect specified");
  }

  const callbackUrl = new URL(currentUrl);
  callbackUrl.pathname = authFinishPath;
  replaceSearchParams(
    callbackUrl,
    new URLSearchParams({
      [REDIRECT_URL_PARAM_KEY]: redirectUrl,
    }),
  );

  return callbackUrl;
};

/**
 * Sends user to the authentication service
 */
export const AuthStartPage: FC<{
  redirect: string;
  authStore: IAuthStore;
}> = ({ redirect, authStore }) => {
  const callbackUrl = getAuthCallbackUrl(
    new URL(window.location.toString()),
    redirect,
  );
  const authUrl = authStore.getAuthUrl(callbackUrl);

  useEffect(() => {
    window.location.replace(authUrl);
  }, [authUrl]);

  return <div>Loading...</div>;
};

const authenticate = async (
  currentUrl: URL,
  authStore: IAuthStore,
): Promise<string> => {
  const redirectUrl = currentUrl.searchParams.get(REDIRECT_URL_PARAM_KEY);
  if (!redirectUrl) {
    throw new Error("No redirect specified");
  }

  const callbackUrl = new URL(currentUrl);
  replaceSearchParams(
    callbackUrl,
    new URLSearchParams({
      [REDIRECT_URL_PARAM_KEY]: redirectUrl,
    }),
  );

  await authStore.authenticate(currentUrl, callbackUrl);
  if (authStore.state !== "authenticated") {
    throw new Error("Couldn't authenticate");
  }

  return redirectUrl;
};

/**
 * Finalizes authenticating after coming from authentication service
 */
export const AuthFinishPage: FC<{ authStore: IAuthStore }> = ({
  authStore,
}) => {
  const navigate = useNavigate();

  const getAndNavigateToRedirectUrl = useCallback(async () => {
    try {
      const redirectUrl = await authenticate(
        new URL(window.location.toString()),
        authStore,
      );

      navigate(redirectUrl);
    } catch (e) {
      console.debug(e);

      authStore.logout();

      const logoutUrl = authStore.getLogoutUrl();
      window.location.replace(logoutUrl);
    }
  }, [authStore, navigate]);

  useEffect(() => {
    void getAndNavigateToRedirectUrl();
  }, [getAndNavigateToRedirectUrl]);

  return <div>Authenticating...</div>;
};

/**
 * Finalizes authenticating after coming from authentication service
 */
export const AuthLogoutPage: FC<{ authStore: IAuthStore }> = ({
  authStore,
}) => {
  const logoutUrl = authStore.getLogoutUrl();

  useEffect(() => {
    authStore.logout();
    window.location.replace(logoutUrl);
  }, [authStore, logoutUrl]);

  return <div>Loading...</div>;
};
