import { Button, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import {
  type ChangeEvent,
  type FormEventHandler,
  useRef,
  useState,
} from "react";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { TextInput } from "../../components/text-input";
import { ProfileValidationError } from "../../services/profile/profileState";

const fullNameStepTestIds = {
  root: "full-name-step",
  firstNameInput: "first-name-input",
  lastNameInput: "last-name-input",
  submitButton: "submit-button",
};

export const FullnameStep = observer(() => {
  const { profileState } = useServices();
  const { profile } = profileState;

  const { navigateTo, navigateNext } = useStepperContext();

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const [firstName, setFirstName] = useState(profile.firstName ?? "");
  const [lastName, setLastName] = useState(profile.lastName ?? "");
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();
    setShowErrors(true);

    const f = firstName.trim();
    const l = lastName.trim();
    if (!f || !l) {
      return;
    }

    try {
      await profileState.setFullName(f, l);
      navigateNext();
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setError(e.nestedErrors[0]?.title ?? "");
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("phone");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} data-test-id={fullNameStepTestIds.root}>
      <StepWrapper>
        <StepTitle>And what’s your full name?</StepTitle>

        <StepDescription>
          So we know what name to put the business account in, and what to call
          you if you get in touch!
        </StepDescription>

        <div>
          <TextInput
            name="first-name"
            label="First name"
            error="First name is required"
            hasError={showErrors && firstName.trim() === ""}
            inputProps={{
              placeholder: "First name",
              testId: fullNameStepTestIds.firstNameInput,
            }}
            value={firstName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value);
            }}
          />

          <Gap $size={2} />

          <TextInput
            name="last-name"
            value={lastName}
            label="Last name"
            error="Last name is required"
            hasError={showErrors && lastName.trim() === ""}
            inputProps={{
              placeholder: "Last name",
              testId: fullNameStepTestIds.lastNameInput,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value);
            }}
          />
        </div>

        <StepActions>
          <SecondaryActionButton text="Back" onClick={handlePrevClick} />
          <Button
            type="primary"
            text="Next"
            onClick={handleNextClick}
            testId={fullNameStepTestIds.submitButton}
          />
        </StepActions>
        {error && <Text color="themeTerracotta">{error}</Text>}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});
