import {
  HttpClient,
  JsonProperty,
  Serializable,
} from "@anna-money/anna-web-lib";
import type { IApiClient } from "../../../api/apiClient";
import type {
  CardDetailsData,
  OnboardingChatStoreApi,
} from "../../../common/chat/onboardingChatStoreApi";

@Serializable()
class SumSubResponse {
  constructor(@JsonProperty() readonly token: string) {}
}

export class ChatStoreApi implements OnboardingChatStoreApi {
  constructor(
    private readonly _apiClient: IApiClient,
    private readonly _httpClient: HttpClient,
  ) {}

  onLogin = async (): Promise<void> => {
    const req = await this._httpClient.request({
      method: "POST",
      url: "/api/scenario/on-login",
      responseType: "json",
      headers: {
        "x-app-version": "0.53.0",
        "x-os-version": "web",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        push_data: null,
      }),
    });
    await req.send();
  };

  saveCardDetails = async (data: CardDetailsData): Promise<void> => {
    await this._apiClient.post("api/onboarding/save-card-info", data);
  };

  getSumSubAccessToken = async (
    flowName: string,
    documentId: string,
  ): Promise<string> => {
    const result = await this._apiClient.post("api/document/get-sumsub-token", {
      flow_name: flowName,
      document_id: documentId,
    });

    return result.getData(SumSubResponse).token;
  };
}
