import { Forms } from "@anna-money/anna-web-ui";
import { type ComponentProps, type FC } from "react";
import styled from "styled-components";

const Input = styled(Forms.TextInput)`
  width: 268px;
`;

interface TextFieldProps extends ComponentProps<typeof Forms.TextInput> {
  hasError?: boolean;
}

export const TextInput: FC<TextFieldProps> = ({ error, hasError, ...rest }) => (
  <Input {...rest} error={hasError ? error : undefined} />
);
