import { type FC, type FormEventHandler, useRef } from "react";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepDescription } from "../../components/stepper/description";
import { StepTitle } from "../../components/stepper/title";
import { type PhoneBasicProps } from "./interfaces";

export const OnboardingPhoneBasic: FC<PhoneBasicProps> = ({
  styles,
  onSubmit,
  testId,
  state,
  content,
}) => {
  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);
  const handleButtonClick = (): void => {
    hiddenSubmitRef.current?.click();
  };
  const isLoading = state === "loading";
  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className={styles.root} onSubmit={handleSubmit} data-test-id={testId}>
      <StepTitle className={styles.title}>
        Thanks for giving us a go!
        <br />
        Now, what’s your mobile phone number?
      </StepTitle>

      <StepDescription className={styles.subTitle}>
        We’ll use this to help you access your account later on.
      </StepDescription>

      {content({
        buttonProps: {
          onClick: handleButtonClick,
          processing: isLoading,
          disabled: isLoading,
          text: isLoading ? "Loading..." : "Next",
          type: "primary",
        },
      })}

      <HiddenSubmit ref={hiddenSubmitRef} disabled={isLoading} />
    </form>
  );
};
