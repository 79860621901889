import { Button, Radio as RadioComponent, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import {
  type ComponentProps,
  type FormEventHandler,
  type FunctionComponent,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { VerticalRadioGroup } from "../../components/vertical-radio-group";
import { ProfileKind } from "../../services/profile/models";
import { ProfileValidationError } from "../../services/profile/profileState";

type RadioComponentProps = ComponentProps<typeof RadioComponent>;

const Radio: FunctionComponent<RadioComponentProps & { required?: boolean }> =
  styled(RadioComponent)``;

const testIds = {
  root: "kind-step",
  soleTraderCheckbox: "sole-trader-checkbox",
  companyCheckbox: "company-checkbox",
  submitButton: "submit-button",
};

export const KindStep = observer(() => {
  const { profileState } = useServices();
  const { profile, setKind: setProfileKind } = profileState;

  const { navigateTo } = useStepperContext();

  const [kind, setKind] = useState<ProfileKind | null>(
    profile.entityType ?? null,
  );
  const [error, setError] = useState<string>();

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();

    if (!kind) {
      return;
    }

    try {
      await setProfileKind(kind);
      navigateTo(
        kind === ProfileKind.soleTrader
          ? "sole-trading-name"
          : "company-number",
      );
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setError(e.nestedErrors[0]?.title ?? "");
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("address");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} data-test-id={testIds.root}>
      <StepWrapper>
        <StepTitle>
          Are you registered with companies house, or are you a sole trader?
        </StepTitle>

        <StepDescription>
          So we know what kind of business you’re running.
        </StepDescription>

        <VerticalRadioGroup
          name="kind"
          value={kind ?? undefined}
          onChange={(e) => {
            setKind((e as any).target.value);
          }}
        >
          <Radio
            value={ProfileKind.soleTrader}
            label="Sole Trader"
            required
            testId={testIds.soleTraderCheckbox}
          />

          <Gap />

          <Radio
            value={ProfileKind.company}
            label="Limited Company"
            required
            testId={testIds.companyCheckbox}
          />
        </VerticalRadioGroup>

        <StepActions>
          <SecondaryActionButton text="Back" onClick={handlePrevClick} />
          <Button
            type="primary"
            text="Next"
            onClick={handleNextClick}
            testId={testIds.submitButton}
          />
        </StepActions>
        {error && <Text color="themeTerracotta">{error}</Text>}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});
