import { type FC } from "react";
import { PhoneOtpStep } from "../../common/containers/phone/phone-otp";
import { OnboardingPhoneOtpClassic } from "../../common/phone/phoneClassic/onboardingPhoneOtpClassic";

export const ClassicPhoneOtpStep: FC = () => (
  <PhoneOtpStep>
    {(props) => (
      <OnboardingPhoneOtpClassic {...props} testId="phone-otp-step" />
    )}
  </PhoneOtpStep>
);
