import { Button } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import {
  type ComponentType,
  type FC,
  type ReactElement,
  useCallback,
  useEffect,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useAnalyticsStepView } from "../common/hooks";
import { useServices } from "../common/services/servicesContext";
import { AnnaLogo } from "../components/graphics/anna-logo";
import { AnnaLogoV2 } from "../components/graphics/anna-logo-v2";
import { TrustPilotRating } from "../components/graphics/trust-pilot-rating";
import { useStepperContext } from "../components/stepper/context";
import { Stepper } from "../components/stepper/stepper";
import { getClassicStep } from "./getClassicStep";
import { AddressStep } from "./steps/address";
import { ChoiceStep } from "./steps/choice/choice";
import { CompanyNumberStep } from "./steps/company-number";
import { ContinueInApp } from "./steps/continue-in-app";
import { DOBStep } from "./steps/dob";
import { FullnameStep } from "./steps/fullname";
import { KindStep } from "./steps/kind";
import { KindSoleNameStep } from "./steps/kind-sole-name";
import { ClassicPhoneStep } from "./steps/phone";
import { ClassicPhoneOtpStep } from "./steps/phoneOtp";
import { ClassicPolicyStep } from "./steps/policy";
import { type ClassicSurveyStep } from "./surveyStep";
import styles from "./classicSurvey.module.scss";

const STEPS: Record<ClassicSurveyStep, ComponentType> = {
  policy: ClassicPolicyStep,
  phone: ClassicPhoneStep,
  "phone-otp": ClassicPhoneOtpStep,
  fullname: FullnameStep,
  dob: DOBStep,
  address: AddressStep,
  kind: KindStep,
  "sole-trading-name": KindSoleNameStep,
  "company-number": CompanyNumberStep,
  choice: ChoiceStep,
  "continue-in-app": ContinueInApp,
};

const Root = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const DesktopLogo = styled(AnnaLogoV2)`
  display: block;

  @media only screen and (max-device-width: 812px) {
    display: none;
  }
`;

const MobileLogo = styled(AnnaLogo)`
  display: block;

  @media only screen and (min-device-width: 813px) {
    display: none;
  }
`;

const HeaderRating = styled.a.attrs({
  href: "https://www.trustpilot.com/review/anna.money",
  target: "_blank",
  rel: "noreferrer",
  children: <TrustPilotRating />,
})`
  display: block;
  font-size: 0;
`;

const Header = styled.header`
  padding: 16px 34px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-device-width: 813px) {
    background-color: #fafafa;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
`;

const StyledContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Sidebar = styled.aside`
  background: #fff6f7;
  min-width: 269px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Main = styled.main`
  display: flex;
  width: 100%;
`;

const InvisibleSidebarRight = styled.aside`
  flex: 1 0 0;
`;

const Globals = createGlobalStyle`
  @media only screen  
  and (max-device-width: 812px) { 
    ${Sidebar}, ${InvisibleSidebarRight} {
      display: none;
    }
  }
`;

const progressImgType = (step: ClassicSurveyStep): "1" | "2" | "3" => {
  switch (step) {
    case "policy":
    case "phone":
    case "phone-otp":
      return "1";
    case "choice":
    case "continue-in-app":
      return "3";
    default:
      return "2";
  }
};
const progressImgSrc = (step: ClassicSurveyStep): string => {
  return `progress/progress-${progressImgType(step)}.svg`;
};

const SetNavigateTo: FC = () => {
  const { profileState } = useServices();
  const { navigateTo } = useStepperContext<ClassicSurveyStep>();

  useEffect(() => {
    profileState.setNavigateTo(navigateTo);
  }, [navigateTo, profileState]);

  return null;
};

const ClassicContent: FC<{
  step: ClassicSurveyStep;
  children: ReactElement;
}> = ({ step, children }) => {
  const { authStore } = useServices();
  useAnalyticsStepView(step);

  const handleLogoutClick = useCallback(() => {
    authStore.logout();

    const logoutUrl = authStore.getLogoutUrl();
    window.location.replace(logoutUrl);
  }, [authStore]);

  const LogoutButton: FC<{ className?: string }> = ({ className }) => (
    <Button
      className={classNames(styles.logoutButton, className)}
      text="Logout"
      onClick={handleLogoutClick}
    />
  );

  return (
    <>
      <SetNavigateTo />
      <Sidebar>
        <div className={styles.progress}>
          <img
            src={progressImgSrc(step)}
            alt="your progress"
            aria-hidden
            draggable={false}
          />
        </div>
        <LogoutButton />
      </Sidebar>
      <Main>
        <div className={styles.mainInner}>
          {children}
          <LogoutButton className={styles.mobileLogoutButton} />
        </div>
      </Main>
      <InvisibleSidebarRight />
    </>
  );
};

export const ClassicSurvey = observer(() => {
  const { userState, profileState } = useServices();

  return (
    <>
      <Globals />

      <Root>
        <Header>
          <div>
            <DesktopLogo />
            <MobileLogo />
          </div>

          <HeaderRating />
        </Header>
        <StyledContent>
          <Stepper
            getStep={getClassicStep}
            steps={STEPS}
            initialStep={getClassicStep(userState.user, profileState.profile)}
          >
            {(Content, step) => {
              return (
                <ClassicContent step={step}>
                  <Content />
                </ClassicContent>
              );
            }}
          </Stepper>
        </StyledContent>
      </Root>
    </>
  );
});
