import { Button, Radio as RadioComponent, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import {
  type ChangeEvent,
  type ComponentProps,
  type FormEventHandler,
  type FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { TextInput } from "../../components/text-input";
import { VerticalRadioGroup } from "../../components/vertical-radio-group";
import { ProfileSoleTrader } from "../../services/profile/models";
import { ProfileValidationError } from "../../services/profile/profileState";

type RadioComponentProps = ComponentProps<typeof RadioComponent>;

const Radio: FunctionComponent<RadioComponentProps & { required?: boolean }> =
  styled(RadioComponent)``;

const testIds = {
  root: "sole-trader-kind-step",
  customNameCheckbox: "custom-name-checkbox",
  customNameInput: "custom-name-input",
  submitButton: "submit-button",
};

export const KindSoleNameStep = observer(() => {
  const { profileState } = useServices();
  const { profile, setSoleTrader } = profileState;

  const { navigateTo } = useStepperContext();

  const [nameStyle, setNameStyle] = useState<"own" | "custom" | null>(() => {
    if (!profile.soleTrader) {
      return null;
    }

    return profile.soleTrader.useOwnName ? "own" : "custom";
  });

  const [customName, setCustomName] = useState(
    profile.soleTrader?.tradingName ?? "",
  );
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (nameStyle === "own") {
      setCustomName("");
    }
  }, [nameStyle]);

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();

    try {
      await setSoleTrader(
        new ProfileSoleTrader(nameStyle === "own", customName),
      );
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setError(e.nestedErrors[0]?.title ?? "");
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("kind");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} data-test-id={testIds.root}>
      <StepWrapper>
        <StepTitle>What name do you trade under?</StepTitle>

        <StepDescription>You’re your own boss. We like that.</StepDescription>

        <div>
          <VerticalRadioGroup
            name="style"
            value={nameStyle ?? undefined}
            onChange={(e) => {
              setNameStyle((e as any).target.value);
            }}
          >
            <Radio value="own" label="I use my own name" required />

            <Gap />

            <Radio
              value="custom"
              label="I use a different name"
              required
              testId={testIds.customNameCheckbox}
            />
          </VerticalRadioGroup>

          <Gap />

          {nameStyle === "custom" && (
            <TextInput
              name="trading-name"
              inputProps={{
                placeholder: "Enter your trading name here",
                testId: testIds.customNameInput,
              }}
              value={customName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCustomName(e.target.value);
              }}
            />
          )}
        </div>

        <StepActions>
          <SecondaryActionButton text="Back" onClick={handlePrevClick} />
          <Button
            type="primary"
            text="Next"
            onClick={handleNextClick}
            testId={testIds.submitButton}
          />
        </StepActions>
        {error && <Text color="themeTerracotta">{error}</Text>}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});
