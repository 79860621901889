import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class WebOnboardingNestedValidationError {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly title: string,
  ) {}
}

@Serializable()
export class WebOnboardingValidationError {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty({ isArray: true, type: WebOnboardingNestedValidationError })
    readonly nestedErrors: WebOnboardingNestedValidationError[],
  ) {}
}
