import { Button, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { type FormEventHandler, useMemo, useRef, useState } from "react";
import { useServices } from "../../common/services/servicesContext";
import { Gap } from "../../components/gap";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { type Company, CompanyStatus } from "../../services/profile/models";
import { ProfileValidationError } from "../../services/profile/profileState";
import { CompanyList } from "./components/company-list";

interface Props {
  companies: Company[];
  loadingCompanies: boolean;
  showSearch: () => void;
}

export const CompanyNumberAutoStep = observer<Props>(
  ({ companies, loadingCompanies, showSearch }) => {
    const { profileState } = useServices();
    const { profile, setCompany, isSettingCompany } = profileState;
    const [companyError, setCompanyError] = useState("");

    const { navigateTo } = useStepperContext();

    const [selectedCompany, setSelectedCompany] = useState<string | undefined>(
      profile.company?.companyNumber ?? undefined,
    );

    const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

    const isInvalidSelection = useMemo(() => {
      if (!selectedCompany || selectedCompany === "_INTERNAL_NONE_") {
        return false;
      }

      return companies.some(
        ({ companyNumber, status }) =>
          companyNumber === selectedCompany && status !== CompanyStatus.active,
      );
    }, [selectedCompany, companies]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (
      e,
    ): Promise<void> => {
      e.preventDefault();

      if (isInvalidSelection) {
        return;
      }

      if (
        (!selectedCompany && !companies.length) ||
        selectedCompany === "_INTERNAL_NONE_"
      ) {
        showSearch();
        return;
      }

      if (!selectedCompany) {
        return;
      }

      try {
        await setCompany(selectedCompany);
      } catch (e) {
        if (e instanceof ProfileValidationError) {
          setCompanyError(e.nestedErrors[0]?.title ?? "");
        }
      }
    };

    const handlePrevClick = (): void => {
      navigateTo("kind");
    };

    const handleNextClick = (): void => {
      hiddenSubmitRef.current?.click();
    };

    return (
      <form onSubmit={handleSubmit}>
        <StepWrapper>
          <StepTitle>
            Great! Please select your company from the list below
          </StepTitle>
          <StepDescription>
            We were literally made for eachother.
          </StepDescription>

          <div>
            {loadingCompanies && (
              <>
                <Gap />
                <Text>Loading ...</Text>
              </>
            )}

            {!loadingCompanies && (companies.length ?? 0) === 0 && (
              <>
                <Gap />
                <Text>
                  We were unable to find companies under your name at Companies
                  House. Press "Next" to find one manually.
                </Text>
              </>
            )}

            {!loadingCompanies && (companies.length ?? 0) > 0 && (
              <>
                <Gap />

                <Text>
                  We found these companies under your name at Companies House:
                </Text>

                <Gap />

                <CompanyList
                  list={[
                    ...companies,
                    {
                      companyNumber: "_INTERNAL_NONE_",
                      title: "None of these",
                      status: CompanyStatus.active,
                    },
                  ]}
                  value={selectedCompany}
                  onChange={setSelectedCompany}
                />
              </>
            )}
          </div>

          <StepActions>
            <SecondaryActionButton
              text="Back"
              disabled={isSettingCompany}
              onClick={handlePrevClick}
            />
            <Button
              type="primary"
              text={isSettingCompany ? "Loading..." : "Next"}
              disabled={isSettingCompany || isInvalidSelection}
              onClick={handleNextClick}
            />
          </StepActions>

          {companyError && <Text color="themeTerracotta">{companyError}</Text>}
        </StepWrapper>

        <HiddenSubmit ref={hiddenSubmitRef} />
      </form>
    );
  },
);
