import { AnnaError, ValidationApiError } from "@anna-money/anna-web-lib";
import { makeAutoObservable, runInAction, when } from "mobx";
import {
  type WebOnboardingNestedValidationError,
  WebOnboardingValidationError,
} from "../../api/validationError";
import { type ClassicSurveyStep } from "../../classicSurvey/surveyStep";
import { type IAppStore } from "../app/appStore";
import { type IProfileApiClient } from "./api";
import {
  type Company,
  OnboardingContinuationMode,
  OnboardingMode,
  type Profile,
  ProfileCompany,
  type ProfileHomeAddress,
  ProfileKind,
  type ProfileSoleTrader,
} from "./models";

export const redirectPath = `/dashboard?firstVisit`;

export class ProfileValidationError extends AnnaError {
  constructor(readonly nestedErrors: WebOnboardingNestedValidationError[]) {
    super("Settings profile validation error");
  }
}

export interface IProfileState {
  updateProfile: () => Promise<void>;
  profile: Profile;
  setProfile: () => Promise<void>;
  getCompaniesByOfficer: () => Promise<void>;
  loadingCompaniesByOfficer: boolean;
  companiesByOfficer?: Company[];
  setFullName: (firstName: string, lastName: string) => Promise<void>;
  setDOB: (dob: Date) => Promise<void>;
  setAddress: (address: ProfileHomeAddress) => Promise<void>;
  setKind: (kind: ProfileKind) => Promise<void>;
  setSoleTrader: (value: ProfileSoleTrader) => Promise<void>;
  isSettingCompany: boolean;
  setCompany: (companyNumber: string) => Promise<void>;
  setNavigateTo: (navigateTo: (step: ClassicSurveyStep) => void) => void;
  activate: () => Promise<void>;
}

export class ProfileState implements IProfileState {
  private readonly _profileApiClient: IProfileApiClient;
  private _navigateTo?: (step: ClassicSurveyStep) => void;

  private _profile: Profile;

  constructor(
    appStore: IAppStore,
    profileApiClient: IProfileApiClient,
    profile: Profile,
  ) {
    this._profile = profile;
    makeAutoObservable(this, {}, { autoBind: true });
    this._profileApiClient = profileApiClient;

    when(
      () => {
        const { activated, onboardingMode, onboardingContinuationMode } =
          this.profile;

        if (onboardingMode === OnboardingMode.noBankingToBanking) {
          return true;
        }

        if (
          onboardingMode === OnboardingMode.chat ||
          (onboardingMode === OnboardingMode.classicWithOptionalChat &&
            onboardingContinuationMode === OnboardingContinuationMode.web)
        ) {
          return activated ?? false;
        }

        return false;
      },
      () => {
        window.location.replace(
          `${appStore.config.businessXUrl}${redirectPath}`,
        );
      },
    );
  }

  setNavigateTo(navigateTo: (step: ClassicSurveyStep) => void): void {
    this._navigateTo = navigateTo;
  }

  get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;
  }

  setProfile = async (): Promise<void> => {
    this.profile = await this._profileApiClient.apiSetProfile(this.profile);
  };

  updateProfile = async (): Promise<void> => {
    this.profile = await this._profileApiClient.apiGetProfile();
  };

  loadingCompaniesByOfficer = false;
  companiesByOfficer?: Company[];

  async getCompaniesByOfficer(): Promise<void> {
    if (!this.profile.lastName) {
      return;
    }

    const { firstName, lastName } = this.profile;

    let fullName = lastName;
    if (firstName) {
      fullName = `${firstName} ${fullName}`;
    }

    this.loadingCompaniesByOfficer = true;

    const list = await this._profileApiClient.apiGetCompaniesByOfficer(
      fullName,
      this.profile.dateOfBirth,
      this.profile.homeAddress?.postcode,
    );

    runInAction(() => {
      this.companiesByOfficer = list;
      this.loadingCompaniesByOfficer = false;
    });
  }

  async setFullName(f: string, l: string): Promise<void> {
    this.profile.firstName = f;
    this.profile.lastName = l;

    try {
      this.profile = await this._profileApiClient.apiSetProfile(this.profile);
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  async setDOB(dob: Date): Promise<void> {
    this.profile.dateOfBirth = dob.toISOString().slice(0, 10);

    try {
      this.profile = await this._profileApiClient.apiSetProfile(this.profile);
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  async setAddress(address: ProfileHomeAddress): Promise<void> {
    if (
      !address.houseNameOrNumber ||
      !address.street ||
      !address.town ||
      !address.postcode
    ) {
      throw new Error("Please, enter your address to proceed");
    }

    this.profile.homeAddress = address;

    try {
      this.profile = await this._profileApiClient.apiSetProfile(this.profile);
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  async setKind(kind: ProfileKind): Promise<void> {
    this.profile.entityType = kind;

    try {
      this.profile = await this._profileApiClient.apiSetProfile(this.profile);
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  async setSoleTrader(value: ProfileSoleTrader): Promise<void> {
    this.profile.entityType = ProfileKind.soleTrader;

    this.profile.soleTrader = value;
    this.profile.company = undefined;

    try {
      this.profile = await this._profileApiClient.apiSetProfile(this.profile);

      if (
        this.profile.onboardingMode !== OnboardingMode.classicWithOptionalChat
      ) {
        await this.activate();
        this._navigateTo?.("continue-in-app");
      } else {
        this._navigateTo?.("choice");
      }
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  isSettingCompany = false;

  async setCompany(companyNumber: string): Promise<void> {
    this.profile.entityType = ProfileKind.company;

    this.profile.company = new ProfileCompany(companyNumber);
    this.profile.soleTrader = undefined;

    this.isSettingCompany = true;

    try {
      await this._profileApiClient.apiSetProfile(this.profile);

      if (
        this.profile.onboardingMode !== OnboardingMode.classicWithOptionalChat
      ) {
        await this.activate();
        this._navigateTo?.("continue-in-app");
      } else {
        this._navigateTo?.("choice");
      }
    } catch (e) {
      this._throwNestedErrors(e);
    } finally {
      runInAction(() => {
        this.isSettingCompany = false;
      });
    }
  }

  async activate(): Promise<void> {
    try {
      await this._profileApiClient.apiActivateProfile();
      await this.updateProfile();
    } catch (e) {
      this._throwNestedErrors(e);
    }
  }

  private readonly _throwNestedErrors = (e: unknown): void => {
    const errors = this._handleError(e)?.[0]?.nestedErrors;
    if (errors) {
      throw new ProfileValidationError(errors);
    }
  };

  private readonly _handleError = (
    e: unknown,
  ): WebOnboardingValidationError[] | null => {
    if (e instanceof ValidationApiError && e.hasErrors) {
      try {
        return e.deserializeArrayErrors(WebOnboardingValidationError);
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    console.error(e);
    return null;
  };
}
