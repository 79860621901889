import { type AttachedListValue, Dropdown } from "@anna-money/anna-web-ui";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { type CountryCode } from "libphonenumber-js";
import { type FC } from "react";
import { type CountryData } from "../../helpers";
import styles from "./PhoneCountryList.module.scss";

interface PhoneCountryListProps {
  className?: string;
  selected: CountryData;
  countries: CountryData[];
  onSelect: (countryCode: CountryCode) => void;
  width: number;
  testId?: string;
}

const ButtonLabel: FC<{
  option: AttachedListValue<string>;
  prefix: string;
}> = ({ option, prefix }) => (
  <div>
    {hasFlag(option.value) ? `${getUnicodeFlagIcon(option.value)} ` : ""}
    {prefix}
  </div>
);

export const PhoneCountryList: FC<PhoneCountryListProps> = ({
  className,
  selected,
  countries,
  onSelect,
  width,
  testId,
}) => {
  const printCallingCode = (callingCode: string): string => `+(${callingCode})`;
  const options: Array<AttachedListValue<CountryCode>> = [...countries]
    .sort((country) => (country.countryName === selected.countryName ? -1 : 1))
    .map(({ countryCode, countryName, callingCode }) => {
      return {
        value: countryCode,
        label: countryName,
        prefix: (
          <div className={styles.listItemPrefix}>
            {printCallingCode(callingCode)}{" "}
            {hasFlag(countryCode) ? `${getUnicodeFlagIcon(countryCode)} ` : ""}
          </div>
        ),
      };
    });
  const searchFilter = (
    option: AttachedListValue<string>,
    input: string,
  ): boolean => {
    const country = countries.find(
      ({ countryCode }) => countryCode === option.value,
    );

    if (!country) {
      return false;
    }

    // search by country code
    if (/\d+/.test(input.toLowerCase())) {
      return country.callingCode
        .toLowerCase()
        .includes(input.toLowerCase().trim());
    }

    return (
      country.countryName?.toLowerCase().includes(input.toLowerCase().trim()) ??
      false
    );
  };

  return (
    <Dropdown
      className={className}
      listWidth={width}
      name="phone-country-list"
      onChange={onSelect}
      testId={testId}
      buttonLabel={(option) => (
        <ButtonLabel
          option={option}
          prefix={printCallingCode(selected.callingCode)}
        />
      )}
      searchFilter={searchFilter}
      value={selected.countryCode}
      options={options}
      newDesign
    />
  );
};
