import { Button, PageTitle, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useServices } from "../../../common/services/servicesContext";
import { StepActions } from "../../../components/stepper/actions";
import { useStepperContext } from "../../../components/stepper/context";
import { type ClassicSurveyStep } from "../../surveyStep";
import { ChoiceStore } from "./choiceStore";
import styles from "./choice.module.scss";

export const ChoiceStep = observer(() => {
  const { navigateTo } = useStepperContext<ClassicSurveyStep>();
  const { profileState } = useServices();
  const choiceStore = useRef(new ChoiceStore(profileState)).current;

  const handleContinueInApp = async (): Promise<void> => {
    await choiceStore.handleContinueInApp();
    navigateTo("continue-in-app");
  };

  const handleContinueHere = async (): Promise<void> => {
    await choiceStore.handleContinueHere();
  };

  return (
    <div>
      <PageTitle className={styles.title}>
        How would you like to continue?
      </PageTitle>
      <Text className={styles.text} tag="div">
        You can finish setting up here on the web, or continue in our app.
        <br />
        ANNA is best experienced in our app, but you can do most of your
        business finances from our website
      </Text>
      <Text className={styles.instructions} tag="div">
        What’s next?
        <br />
        <ol>
          <li>Have your ID handy to finish your application</li>
          <li>Answer a few questions about your business</li>
          <li>
            Submit your application, we’ll check everything and get your account
            set up
          </li>
        </ol>
      </Text>
      <StepActions>
        <Button
          text="Continue on the web"
          onClick={handleContinueHere}
          processing={choiceStore.continueHereState === "loading"}
        />
        <Button
          text="Download the app"
          onClick={handleContinueInApp}
          processing={choiceStore.continueInAppState === "loading"}
        />
      </StepActions>
      {choiceStore.continueHereState instanceof Error && (
        <Text color="themeTerracotta">
          {choiceStore.continueHereState.message}
        </Text>
      )}
      {choiceStore.continueInAppState instanceof Error && (
        <Text color="themeTerracotta">
          {choiceStore.continueInAppState.message}
        </Text>
      )}
    </div>
  );
});
