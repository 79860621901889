import { type UploadIdCardAction } from "@anna-money/anna-web-chat";
import { AnnaError } from "@anna-money/anna-web-lib";
import {
  type MessageHandler,
  type SnsWebSdkBaseConfig,
  type SnsWebSdkOptions,
} from "@sumsub/websdk";
import SumsubWebSdk from "@sumsub/websdk-react";
import { observer } from "mobx-react-lite";
import { type FC, useCallback } from "react";
import { DetailsPanel } from "../../components/DetailsPanel/detailsPanel";

type Props = {
  payload: UploadIdCardAction["payload"];
  onClose: VoidFunction;
  getAccessToken: (flowName: string, documentId: string) => Promise<string>;
  onIdVerificationMessage?: MessageHandler;
};

const sumsubConfig: SnsWebSdkBaseConfig = {
  lang: "en",
};
const sumsubOptions: SnsWebSdkOptions = {
  addViewportTag: false,
  adaptIframeHeight: true,
};

export const IdVerificationPanel: FC<Props> = observer(
  ({ payload, onClose, getAccessToken, onIdVerificationMessage }) => {
    const expirationHandler = useCallback(async (): Promise<string> => {
      return await getAccessToken(payload.flowName, payload.documentId);
    }, [payload, getAccessToken]);

    return (
      <DetailsPanel
        name="id-verification"
        title="ID verification"
        onClose={onClose}
      >
        <SumsubWebSdk
          accessToken={payload.accessToken}
          expirationHandler={expirationHandler}
          config={sumsubConfig}
          options={sumsubOptions}
          onMessage={onIdVerificationMessage}
          onError={(error) => {
            console.error(
              new AnnaError("Id verification error", { extra: { error } }),
            );
          }}
        />
      </DetailsPanel>
    );
  },
);
