import { makeAutoObservable } from "mobx";

export interface IConfig {
  appVersion: string;
  annaAuthUrl: string;
  annaApiUrl: string;
  businessXUrl: string;
  chatApiUrl: string;
  chatWsUrl: string;
  mediaApiUrl: string;
  sentryDSN: string;
}

export interface IAppStore {
  config: IConfig;
  clientId: string;
}

export class AppStore implements IAppStore {
  private readonly _config: IConfig;

  constructor(config: IConfig) {
    makeAutoObservable(this);
    this._config = config;
  }

  get config(): IConfig {
    return this._config;
  }

  clientId = "5521324df7977c3f442b7544e090b68e7";
}
