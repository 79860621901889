import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { useEffect, useState } from "react";
import type { IApiClient } from "../../../api/apiClient";
import { useServices } from "../../../common/services/servicesContext";

@Serializable()
class MagicLink {
  constructor(@JsonProperty() readonly magicLink: string) {}
}

export const useDeepLink = (): string => {
  const { apiClient } = useServices();
  const [deepLink, setDeepLink] = useState("");

  useEffect(() => {
    void getMagicLink(apiClient).then((r) => {
      setDeepLink(r);
    });
  }, [apiClient]);

  return deepLink;
};

async function getMagicLink(apiClient: IApiClient): Promise<string> {
  const r = await apiClient.get("api/auth/v2/magic-link/generate", {});

  return r.getData(MagicLink).magicLink;
}
