import { Button, Text } from "@anna-money/anna-web-ui";
import { observer } from "mobx-react-lite";
import {
  type ChangeEvent,
  type ComponentProps,
  type FC,
  type FormEventHandler,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { useServices } from "../../common/services/servicesContext";
import { HiddenSubmit } from "../../components/hidden-submit";
import { StepActions } from "../../components/stepper/actions";
import { useStepperContext } from "../../components/stepper/context";
import { StepDescription } from "../../components/stepper/description";
import { SecondaryActionButton } from "../../components/stepper/secondary-action-button";
import { StepTitle } from "../../components/stepper/title";
import { StepWrapper } from "../../components/stepper/wrapper";
import { TextInput } from "../../components/text-input";
import { ProfileValidationError } from "../../services/profile/profileState";

export const testIds = {
  root: "dob-step",
  input: "dob-input",
  submitButton: "submit-button",
};

const DOBTextField: FC<ComponentProps<typeof TextInput>> = ({
  onChange,
  value,
  ...props
}) => (
  <InputMask
    mask="99/99/9999"
    maskPlaceholder={null}
    value={value}
    onChange={onChange}
  >
    <TextInput {...props} />
  </InputMask>
);

const YEARS_18_MS = 1000 * 60 * 60 * 24 * 365 * 18;

export const DOBStep = observer(() => {
  const { profileState } = useServices();
  const { profile, setDOB } = profileState;

  const { navigateTo, navigateNext } = useStepperContext();

  const hiddenSubmitRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState(() =>
    dateStringToDOBString(profile.dateOfBirth ?? ""),
  );
  const [error, setError] = useState<string>();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    e,
  ): Promise<void> => {
    e.preventDefault();

    const isValid =
      /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(19[0-9]{1}[0-9]{1}|20[0-2]{1}[0-9]{1})$/.test(
        value,
      );

    setError(isValid ? undefined : "Please match the request format");

    const dobString = value.trim();
    if (!dobString || !isValid) {
      return;
    }

    try {
      await setDOB(dobStringToDate(dobString));

      navigateNext();
    } catch (e) {
      if (e instanceof ProfileValidationError) {
        setError(e.nestedErrors[0]?.title ?? "");
      }
    }
  };

  const handlePrevClick = (): void => {
    navigateTo("fullname");
  };

  const handleNextClick = (): void => {
    hiddenSubmitRef.current?.click();
  };

  return (
    <form onSubmit={handleSubmit} data-test-id={testIds.root}>
      <StepWrapper>
        <StepTitle>What’s your date of birth?</StepTitle>

        <StepDescription>
          So we know you’re over 18 and can match you to your ID documents.
        </StepDescription>

        <div>
          <DOBTextField
            name="date-of-birth"
            value={value}
            label="Date of birth (e.g. 20/12/1985)"
            inputProps={{
              placeholder: "dd/mm/yyyy",
              testId: testIds.input,
            }}
            error={error}
            hasError={Boolean(error)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value);
            }}
          />
        </div>

        <StepActions>
          <SecondaryActionButton text="Back" onClick={handlePrevClick} />
          <Button
            type="primary"
            text="Next"
            onClick={handleNextClick}
            testId={testIds.submitButton}
          />
        </StepActions>
        {error && <Text color="themeTerracotta">{error}</Text>}
      </StepWrapper>

      <HiddenSubmit ref={hiddenSubmitRef} />
    </form>
  );
});

function dobStringToDate(dobStr: string): Date {
  const [day, month, year] = dobStr.split("/");

  const d = new Date(`${year}-${month}-${day}`);
  if (isNaN(d.getTime())) {
    throw new Error("Please enter a valid date");
  }

  if (new Date().getTime() - d.getTime() < YEARS_18_MS) {
    throw new Error("You must be 18 or older to continue");
  }

  return d;
}

function dateStringToDOBString(v: string): string {
  const date = new Date(v);

  if (isNaN(date.getTime())) {
    return "";
  }

  return date.toLocaleDateString("en-UK");
}
