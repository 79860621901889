import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { type FC, useEffect, useState } from "react";
import styled from "styled-components";
import type { IApiClient } from "../../../api/apiClient";
import { useServices } from "../../../common/services/servicesContext";

const Img = styled.img.attrs({
  draggable: false,
  alt: "QR code",
  "aria-hidden": true,
})`
  width: 100%;
`;

@Serializable()
class QrCode {
  constructor(
    @JsonProperty() readonly data: string,
    @JsonProperty({ name: "mediaType" }) readonly mediaType: string,
    @JsonProperty() readonly encoding: string,
  ) {}
}

export const AnnaQR: FC<{ magicLink: string }> = (props) => {
  const { apiClient } = useServices();
  const objectURL = useQRObjectURL(props.magicLink, apiClient);

  if (!objectURL) {
    return null;
  }

  return <Img src={objectURL} />;
};

function useQRObjectURL(magicLink: string, apiClient: IApiClient): string {
  const [objectURL, setObejctURL] = useState<string>("");

  useEffect(() => {
    void getQRObjectURL(magicLink, apiClient).then((r) => {
      setObejctURL(r);
    });
  }, [apiClient, magicLink]);

  return objectURL;
}

async function getQRObjectURL(
  magicLink: string,
  apiClient: IApiClient,
): Promise<string> {
  const response = await apiClient.post("api/qr-code/i/qr-code/generate", {
    text: magicLink,
  });
  const { mediaType, data, encoding } = response.getData(QrCode);

  const ir = await fetch(`data:${mediaType};${encoding},${data}`);
  const ib = await ir.blob();

  return URL.createObjectURL(ib);
}
