import { observer } from "mobx-react-lite";
import { type FC, useEffect } from "react";
import { OnboardingChat } from "../../../common/chat/onboardingChat";
import { useServices } from "../../../common/services/servicesContext";
import "@anna-money/anna-web-chat/dist/index.css";

export const chatStepTestIds = {
  root: "chat-step",
};

export const ChatStep: FC = observer(() => {
  const { userState, appStore } = useServices();
  const { chatStore, analyticsClient, authHandler } = useServices();

  useEffect(() => {
    void analyticsClient.postEvent(
      "webonboarding.step_view",
      {
        step: "chat",
      },
      userState.alias,
    );
  }, [userState.alias, analyticsClient]);

  return (
    <OnboardingChat
      chatProps={{
        alias: userState.alias,
        authHandler,
        apiConfig: appStore.config,
        thread: null,
        testId: chatStepTestIds.root,
      }}
      chatAutoLayoutPanelProps={{
        title: "Get an ANNA Business Account",
      }}
      autoLayoutName="onboarding-chat"
      onIdVerificationMessage={(type, payload) => {
        void analyticsClient.postEvent(
          "webonboarding.id_verification_message",
          {
            type,
            payload,
          },
        );
      }}
      chatStore={chatStore}
    />
  );
});
