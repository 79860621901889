import { makeAutoObservable } from "mobx";
import { type UserApiClient } from "./api";
import { type User } from "./models";

export interface IUserState {
  user: User;
  alias: string;
  setUnverifiedPhoneNumber: (phoneNumber: string) => void;
  updateUser: () => Promise<void>;
  updateUserAndAgreements: () => Promise<void>;
}

export class UserState {
  private _user: User;
  private readonly _userApiClient: UserApiClient;

  private constructor(userApiClient: UserApiClient, user: User) {
    this._user = user;
    makeAutoObservable(this, {}, { autoBind: true });
    this._userApiClient = userApiClient;
  }

  get user(): User {
    return this._user;
  }

  private set user(user: User) {
    this._user = user;
  }

  get alias(): string {
    return this.user.alias;
  }

  setUnverifiedPhoneNumber = (phoneNumber: string): void => {
    this.user = {
      ...this.user,
      phoneNumber,
      isPhoneNumberVerified: false,
    };
  };

  updateUser = async (): Promise<void> => {
    const user = await this._userApiClient.apiGetUser();
    this.user = {
      ...user,
      isPolicyAccepted: this.user.isPolicyAccepted,
    };
  };

  updateUserAndAgreements = async (): Promise<void> => {
    const user = await this._userApiClient.apiGetUser();
    const { customerAgreement, privacyPolicy, termsAndConditions } =
      await this._userApiClient.apiGetAgreements();
    this.user = {
      ...user,
      isPolicyAccepted:
        customerAgreement && privacyPolicy && termsAndConditions,
    };
  };

  static async create(userApiClient: UserApiClient): Promise<UserState> {
    const user = await userApiClient.apiGetUser();
    const { customerAgreement, privacyPolicy, termsAndConditions } =
      await userApiClient.apiGetAgreements();
    user.isPolicyAccepted =
      customerAgreement && privacyPolicy && termsAndConditions;
    return new UserState(userApiClient, user);
  }
}
