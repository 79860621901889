import { makeAutoObservable } from "mobx";
import { type IAuthenticator } from "./authenticator";

type AuthStoreState = "unauthenticated" | "authenticating" | "authenticated";

export interface IAuthStore {
  state: AuthStoreState;
  initialize: VoidFunction;
  getAuthUrl: (callbackUrl: URL) => string;
  getLogoutUrl: () => string;
  getToken: () => string | undefined;
  authenticate: (currentUrl: URL, callbackUrl: URL) => Promise<void>;
  logout: () => void;
}

export class AuthStore implements IAuthStore {
  private readonly _authenticator: IAuthenticator;

  constructor(authenticator: IAuthenticator) {
    makeAutoObservable(this);
    this._authenticator = authenticator;
  }

  private _state: AuthStoreState = "unauthenticated";

  get state(): AuthStoreState {
    return this._state;
  }

  private set state(state: AuthStoreState) {
    this._state = state;
  }

  initialize(): void {
    this._state = this._authenticator.isAuthenticated
      ? "authenticated"
      : "unauthenticated";
  }

  getAuthUrl(callbackUrl: URL): string {
    return this._authenticator.getAuthUrl(callbackUrl);
  }

  getLogoutUrl(): string {
    return this._authenticator.getLogoutUrl();
  }

  getToken(): string | undefined {
    try {
      return this._authenticator.accessToken;
    } catch (e) {
      this.state = "unauthenticated";
      return undefined;
    }
  }

  async authenticate(currentUrl: URL, callbackUrl: URL): Promise<void> {
    this.state = "authenticating";
    try {
      const authenticated = await this._authenticator.authenticate(
        currentUrl,
        callbackUrl,
      );
      this.state = authenticated ? "authenticated" : "unauthenticated";
    } catch (e) {
      this.state = "unauthenticated";
      throw e;
    }
  }

  logout = async (): Promise<void> => {
    await this._authenticator.logout();
    window.location.replace(this.getLogoutUrl());
  };
}
