import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class User {
  isPolicyAccepted = false;

  constructor(
    @JsonProperty() readonly alias: string,
    @JsonProperty() readonly email: string,
    @JsonProperty() public isPhoneNumberVerified: boolean,
    @JsonProperty({ required: false, type: String })
    public phoneNumber?: string,
  ) {}
}
