import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { type IApiClient } from "../api/apiClient";
import { OnboardingMode } from "../services/profile/models";
import { type IProfileStore } from "../services/profile/profileStore";

export const sendAttributionData = (
  data: Record<string, unknown>,
  apiClient: IApiClient,
): void => {
  void apiClient.post("api/web-onboarding/lead", data);
};

export const useAttribution = (
  profileStore: IProfileStore,
  apiClient: IApiClient,
): void => {
  const [query] = useSearchParams();

  useEffect(() => {
    if (!profileStore.isReady(profileStore.state)) {
      return;
    }
    const params = toPlainObject(query);
    if (profileStore.state.profile.onboardingMode === OnboardingMode.chat) {
      params.lead_type = "webonboarding_v2";
    }
    sendAttributionData(params, apiClient);
  }, [query, profileStore.state, profileStore, apiClient]);
};

function toPlainObject(search: URLSearchParams): Record<string, unknown> {
  const params: Record<string, unknown> = {};
  for (const [name, value] of Array.from(search.entries())) {
    params[name] = value;
  }
  return params;
}
