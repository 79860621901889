import { Button, Checkbox, TextItem } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { type ComponentProps, type FC } from "react";
import img from "./img.png";
import { type AgreementType, type PolicyProps } from "./interfaces";
import styles from "./policy.module.scss";

const agreementTexts: Record<AgreementType, string> = {
  privacyPolicy: "privacy policy",
  customerAgreement: "customer agreement",
  termsAndConditions: "terms and conditions",
};

const agreementLinks: Record<AgreementType, string> = {
  privacyPolicy: "https://anna.money/privacy-policy",
  customerAgreement: "https://anna.money/customer-agreement",
  termsAndConditions: "https://anna.money/terms-and-conditions",
};

export const onboardingPolicyTestIds = {
  checkbox: (type: AgreementType): string => `checkbox-${type}`,
  submitButton: "submit-button",
};

const CheckboxLabel: FC<{ agreementType: AgreementType }> = ({
  agreementType,
}) => {
  return (
    <div className={styles.label}>
      <TextItem size="small">I accept the</TextItem>&nbsp;
      <TextItem size="small" weight="bold">
        <a
          target="_blank"
          className={styles.link}
          href={agreementLinks[agreementType]}
          rel="noreferrer"
        >
          {agreementTexts[agreementType]}
        </a>
      </TextItem>
    </div>
  );
};

type Props = PolicyProps & {
  checkboxSize?: ComponentProps<typeof Checkbox>["size"];
  buttonSize?: ComponentProps<typeof Button>["size"];
  testId?: string;
};

export const OnboardingPolicyClassic = observer<Props>(
  ({ className, checkboxSize, buttonSize, store, onSubmit, testId }) => {
    return (
      <div className={classNames(styles.root, className)} data-test-id={testId}>
        <div className={styles.content}>
          <TextItem className={styles.title} weight="bold" size="large">
            Let’s make this official!
          </TextItem>
          <TextItem size="small" className={styles.subTitle}>
            To start enjoying ANNA’s services,
            <br />
            please read and accept these agreements
            <br />
            <br />
            We use your data in line with our ANNA’s privacy policy
            <br />
            which includes the potential consequences of third parties
            using&nbsp; your data
          </TextItem>
          <Checkbox
            size={checkboxSize}
            className={styles.checkbox}
            checked={store.agreements.customerAgreement}
            name="customer-agreement"
            label={<CheckboxLabel agreementType="customerAgreement" />}
            onChange={() => {
              store.toggleAgreement("customerAgreement");
            }}
            testId={onboardingPolicyTestIds.checkbox("customerAgreement")}
          />
          <Checkbox
            size={checkboxSize}
            className={styles.checkbox}
            name="terms-and-conditions"
            checked={store.agreements.termsAndConditions}
            label={<CheckboxLabel agreementType="termsAndConditions" />}
            onChange={() => {
              store.toggleAgreement("termsAndConditions");
            }}
            testId={onboardingPolicyTestIds.checkbox("termsAndConditions")}
          />
          <Checkbox
            size={checkboxSize}
            className={styles.checkbox}
            name="privacy-policy"
            checked={store.agreements.privacyPolicy}
            label={<CheckboxLabel agreementType="privacyPolicy" />}
            onChange={() => {
              store.toggleAgreement("privacyPolicy");
            }}
            testId={onboardingPolicyTestIds.checkbox("privacyPolicy")}
          />
          <Button
            className={styles.button}
            size={buttonSize}
            text="Continue"
            disabled={!store.isAccepted}
            processing={store.submitState === "loading"}
            onClick={onSubmit}
            testId={onboardingPolicyTestIds.submitButton}
          />
          {store.submitState === "error" && (
            <TextItem className={styles.error} size="small">
              Something went wrong
            </TextItem>
          )}
        </div>
        <img alt="logo" src={img} className={styles.img} />
      </div>
    );
  },
);
