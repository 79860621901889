import { action, computed, makeObservable, observable } from "mobx";
import { type OnboardingPhoneStoreApi } from "./onboardingPhoneStoreApi";

export type PhoneState = "loading" | "idle" | Error;

export class OnboardingPhoneStore<
  T extends OnboardingPhoneStoreApi = OnboardingPhoneStoreApi,
> {
  protected _phoneCorrelationId: string | null = null;
  protected _phoneNumber: string | null = null;
  protected _restOtpSeconds: number = 60;
  protected _timer: number | null = null;
  protected _savePhoneState: PhoneState = "idle";
  protected _confirmPhoneState: PhoneState = "idle";

  constructor(protected readonly _api: T) {
    makeObservable(this, {
      _phoneNumber: observable,
      phoneNumber: computed,
      _restOtpSeconds: observable,
      restOtpSeconds: computed,
      _savePhoneState: observable,
      savePhoneState: computed,
      _confirmPhoneState: observable,
      confirmPhoneState: computed,
      savePhoneNumber: action,
      confirmPhoneNumber: action,
      resendOtpCode: action,
    } as any);
  }

  get phoneNumber(): string {
    return this._phoneNumber ?? "";
  }

  protected set phoneNumber(number: string) {
    this._phoneNumber = number;
  }

  get restOtpSeconds(): number {
    return this._restOtpSeconds;
  }

  protected set restOtpSeconds(seconds: number) {
    this._restOtpSeconds = seconds;
  }

  get savePhoneState(): PhoneState {
    return this._savePhoneState;
  }

  protected set savePhoneState(state: PhoneState) {
    this._savePhoneState = state;
  }

  get confirmPhoneState(): PhoneState {
    return this._confirmPhoneState;
  }

  protected set confirmPhoneState(state: PhoneState) {
    this._confirmPhoneState = state;
  }

  async savePhoneNumber(phone: string): Promise<void> {
    this.clearOtpTimer();
    this.savePhoneState = "loading";
    try {
      const { correlationId } = await this._api.initializePhoneNumberChange();
      this._phoneCorrelationId = correlationId;
      this.phoneNumber = phone;

      const { resendDelay } = await this._api.savePhoneNumber(
        this.phoneNumber,
        this._phoneCorrelationId,
      );
      this.startOtpTimer();
      this.restOtpSeconds = resendDelay;
      this.savePhoneState = "idle";
    } catch (e) {
      this.savePhoneState = e as Error;
      throw e;
    }
  }

  async confirmPhoneNumber(code: string): Promise<void> {
    this.confirmPhoneState = "loading";
    try {
      if (this._phoneCorrelationId) {
        await this._api.confirmPhoneNumber(code, this._phoneCorrelationId);
        this.confirmPhoneState = "idle";
      }
    } catch (e) {
      this.confirmPhoneState = e as Error;
      throw e;
    }
  }

  resendOtpCode = async (): Promise<void> => {
    if (this._phoneCorrelationId) {
      this.clearOtpTimer();
      const { resendDelay } = await this._api.resendOTP(
        this._phoneCorrelationId,
      );
      this.restOtpSeconds = resendDelay;
    }
  };

  startOtpTimer(): void {
    this._timer = window.setInterval(() => {
      this.restOtpSeconds--;

      if (this.restOtpSeconds <= 0 && this._timer) {
        window.clearInterval(this._timer);
      }
    }, 1000);
  }

  clearOtpTimer(): void {
    if (this._timer) {
      window.clearInterval(this._timer);
    }
  }
}
